import React, { useState, useRef, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	VStack,
	Heading,
	Button,
	Text
} from '@chakra-ui/react';
import { useRollbar } from '@rollbar/react';
import * as ActionCable from '@rails/actioncable';

import { useAppDispatch, useAppSelector } from 'store';
import { SELF_SERVE_API } from 'store/api';
import { selectAccessToken } from 'store/slices/contentSlice';
import { updateVerified } from 'store/slices/createCourseFormSlice';

const INSTRUCTOR_VERIFIED_NOTIFICATION_TYPE = 'selfserve.user.verified_faculty';

/**
 * This component:
 *
 * - establishes an ActionCable connection to Core
 * - when the current user is vetted-approved in Core, we receive a notification via ActionCable
 * - when we receive this notification, attempt to automatically accept their approval via a POST to
 *   Core's /signup/instructor/accept_verification route
 * - if this is successful, show a modal dialog alerting the user that they've been approved
 *   and now have full access to webtexts
 */
const InstructorApprovedModal: React.VFC = () => {
	const dispatch = useAppDispatch();
	const rollbar = useRollbar();
	const jwt = useAppSelector(selectAccessToken);
	const [isModalOpen, setModalOpen] = useState(false);

	const handleModalClose = () => {
		dispatch(updateVerified(true));
		setModalOpen(false);
	};

	useActionCable(jwt, (data) => {
		if (data.type === INSTRUCTOR_VERIFIED_NOTIFICATION_TYPE) {
			// if we've received a notification that the instructor was vetted-approved,
			// then attempt to automatically accept the approval for them via a POST.
			// if that succeeds, we open a modal notifying them that they now have full access.
			const url = new URL(SELF_SERVE_API.ACCEPT_VERIFICATION);
			url.searchParams.append('jwt', jwt);
			fetch(url.href, { method: 'POST' })
				.then((response) => {
					if (response.ok) {
						// we only want to show the modal if the auto-accept was successful,
						// because otherwise it would be confusing to show a modal that has no effect when acknowledged.
						setModalOpen(true);
					} else {
						rollbar.error('Failed to auto-accept verification', response);
					}
				})
				.catch((e) => {
					rollbar.error('Network error occurred when accepting verification', e);
				});
		}
	});

	return (
		<Modal isOpen={isModalOpen} onClose={handleModalClose} size="4xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack py="32" px="24" spacing="12">
						<Heading size="2xl">You’ve been verified!</Heading>

						<Text>
							You’ve just been approved for an instructor account. Enjoy full access to our
							webtexts!
						</Text>

						<Button onClick={handleModalClose}>Continue</Button>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default InstructorApprovedModal;

interface PushNotification {
	type: string;
	[otherProperties: string]: unknown;
}

function useActionCable(jwt: string, onDataReceived: (data: PushNotification) => void) {
	const rollbar = useRollbar();
	const consumer = useRef<ActionCable.Consumer | null>(null);

	useEffect(() => {
		if (!consumer.current) {
			const url = new URL(SELF_SERVE_API.ACTION_CABLE);
			url.searchParams.append('jwt', jwt);
			consumer.current = ActionCable.createConsumer(url.href);

			if (consumer.current.subscriptions.subscriptions.length === 0) {
				const connectOptions = {
					channel: 'PushNotificationsChannel'
				};
				consumer.current.subscriptions.create(connectOptions, {
					received(data) {
						onDataReceived(data);
					},
					rejected() {
						rollbar.error('Attempt to connect to ActionCable was rejected');
					}
				});
			}
		}

		return () => {
			if (consumer.current) {
				consumer.current.disconnect();
				consumer.current = null;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
