import React, { forwardRef } from 'react';
import { Input, InputProps, Select, HStack, chakra, Box } from '@chakra-ui/react';
import { getYear, getMonth, parseISO, formatISO } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { range } from 'utils';

interface Props {
	id?: string;
	date: string;
	setDate: (date: string) => void;
	simpleInput?: boolean;
	characterLimit?: number;
	maxDate?: string;
}

const DatePicker: React.FC<Props> = (props) => {
	const { id, date, setDate, characterLimit, maxDate } = props;

	const years = range(getYear(new Date()), getYear(new Date()) + 3);
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	const CustomInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
		<Input
			className="chakra-input-for-date-picker"
			onClick={props.onClick}
			defaultValue={props.value}
			ref={ref}
		/>
	));
	CustomInput.displayName = 'customInput';

	const CustomSimpleInput = forwardRef<
		typeof chakra.input,
		React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
	>((props, ref) => {
		return (
			<chakra.input
				border="solid 1px #979797"
				rounded="sm"
				size={characterLimit}
				p="1"
				{...props}
				ref={ref as unknown as React.MutableRefObject<HTMLInputElement>}
			/>
		);
	});
	CustomSimpleInput.displayName = 'customSimpleInput';

	return (
		<Box id={id}>
			<ReactDatePicker
				autoFocus={false}
				renderCustomHeader={({ date, changeYear, changeMonth }) => (
					<HStack mx="4" my="1" display="flex" justifyContent="space-between">
						<Select
							flex="3"
							variant="flushed"
							size="sm"
							value={months[getMonth(date)]}
							onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
							{months.map((option) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</Select>

						<Select
							flex="2"
							variant="flushed"
							size="sm"
							value={getYear(date)}
							onChange={({ target: { value } }) => changeYear(Number(value))}>
							{years.map((option) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</Select>
					</HStack>
				)}
				selected={date ? parseISO(date) : new Date()}
				onChange={(date: Date) => (date ? setDate(formatISO(date)) : null)}
				customInput={props.simpleInput ? <CustomSimpleInput /> : <CustomInput />}
				minDate={new Date()}
				maxDate={maxDate ? parseISO(maxDate) : undefined}
			/>
		</Box>
	);
};

export default DatePicker;
