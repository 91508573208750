import { useState, useEffect, useRef } from 'react';

export function useWindowSize(): { width: number; height: number } {
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		}

		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
}

export function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export { useChapterOrganization } from './chapterOrganizationHooks';
export { useIntegrations } from './integrationsHooks';
export { useTermInformation } from './termInformationHooks';
