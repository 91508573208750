export const Input = {
	parts: ['field'],
	baseStyle: {
		borderRadius: 0
	},
	sizes: {
		md: {
			field: {
				fontSize: '1rem',
				py: 6,
				borderRadius: 0
			}
		}
	},
	defaultProps: {
		size: 'md'
	}
};
