import React, { useRef } from 'react';
import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	HStack,
	Link,
	LinkBox,
	LinkOverlay,
	List,
	Icon,
	Text,
	useDisclosure
} from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';

import { useGetCoursesQuery } from 'store/api';
import { Course } from 'types';
import { useTrackEvent } from 'hooks';
import { useAppSelector } from 'store';
import { selectGlobalState } from 'store/slices/createCourseFormSlice';

const NavigationDrawer: React.FC = (props) => {
	const { children } = props;
	const trackEvent = useTrackEvent();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data: courses } = useGetCoursesQuery();
	const btnRef = useRef<HTMLAnchorElement>(null);
	/**
	 * `webtext` is the textbook id of the last created / edited course
	 */
	const { webtext: currentTextbookId } = useAppSelector(selectGlobalState);

	const formatCourseItem = (course: Course) => {
		if (course.self_serve_status === 'draft') {
			return `Draft Course: ${course.name} ${course.number}`;
		} else if (course.self_serve_status === 'ready_for_review') {
			return `In Review: ${course.name} ${course.number}`;
		} else if (course.self_serve_status === 'rejected') {
			return `Archived: ${course.name} ${course.number}`;
		} else {
			return `${course.name} ${course.number}`;
		}
	};

	return (
		<>
			<Link ref={btnRef} fontWeight="bold" onClick={onOpen}>
				{children}
			</Link>

			<Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent background="background.500">
					<DrawerCloseButton />
					<DrawerHeader background="background.600">Courses</DrawerHeader>

					<DrawerBody>
						<List spacing="2">
							<LinkBox as="li" mb="4">
								<LinkOverlay
									href={currentTextbookId ? `/preview/${currentTextbookId}` : `/preview`}
									onClick={() => {
										/**
										 * Track when the user is returns to /preview from using
										 * this link
										 */
										trackEvent('webtext-preview-launched', {
											from: 'clicked-create-new-course-button'
										});
									}}>
									<Text>
										<Icon as={GrAdd} mb="1" mr="1.5" fontSize="1.1em" />
										Create new course
									</Text>
								</LinkOverlay>
							</LinkBox>

							{courses &&
								courses.map((c) => (
									<LinkBox as="li" key={c.id}>
										<LinkOverlay
											href={
												c.self_serve_status === 'draft'
													? `/review/${c.id}`
													: `/under-review/${c.id}`
											}>
											<HStack>
												<Text>{formatCourseItem(c)}</Text>
											</HStack>
										</LinkOverlay>
									</LinkBox>
								))}
						</List>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
};
export default NavigationDrawer;
