const LmsOptions = ['Canvas', 'Blackboard', 'Brightspace (D2L)', 'Moodle', 'Other'];

const ExampleTerms = [
	'Fall Quarter',
	'Fall Semester',
	'Winter Quarter',
	'January Term or Wintermester',
	'Spring Quarter',
	'Spring Semester',
	'Summer Quarter',
	'Summer I',
	'Summer II'
];

export { LmsOptions, ExampleTerms };
