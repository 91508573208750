import React, { ReactElement } from 'react';
import { IconButton, HStack, Text, Heading } from '@chakra-ui/react';

import { ChapterConfig } from 'types';

export interface DragAndDropItemProps {
	icon?: ReactElement;
	onItemActionClick?: () => void;
	disabled?: boolean;
	removed?: boolean;
	slotIndex?: number;
	actionAriaLabel?: string;
	actionButtonId?: string;
	chapterConfig?: ChapterConfig;
}

const DragAndDropItem: React.FC<DragAndDropItemProps> = (props) => {
	const {
		icon,
		onItemActionClick,
		disabled,
		removed,
		slotIndex,
		actionAriaLabel,
		actionButtonId,
		chapterConfig,
		children
	} = props;

	const shouldDisplaySlotIndex = slotIndex && !removed;
	const shouldShowIcon = icon && (chapterConfig?.removable || disabled);

	return (
		<HStack pb={shouldDisplaySlotIndex ? '2' : ''} justifyContent="flex-start" userSelect="none">
			{shouldDisplaySlotIndex && (
				<Heading size="md" flex="1" textAlign="right" mr="1">
					{slotIndex}
				</Heading>
			)}
			<HStack
				flex="24"
				bg={!disabled && !chapterConfig?.draggable ? 'background.200' : 'white'}
				border={`1px solid ${disabled || !chapterConfig?.draggable ? 'transparent' : 'black'}`}
				borderRadius="md"
				py={slotIndex ? '2' : '1'}
				px={disabled ? '0' : '2'}
				mb={disabled ? '1.5' : '2'}
				align="center"
				style={{ marginTop: 0 }}>
				{shouldShowIcon && (
					<IconButton
						__css={{
							_disabled: {
								color: 'black'
							}
						}}
						disabled={disabled}
						aria-label={actionAriaLabel || `${removed ? 'Unremove' : 'Remove'} chapter`}
						icon={icon}
						variant="outline"
						mb="0"
						p="1"
						onClick={onItemActionClick}
						id={actionButtonId}
					/>
				)}
				<Text>{children}</Text>
			</HStack>
		</HStack>
	);
};

export default DragAndDropItem;
