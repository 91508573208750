import React from 'react';
import {
	FormControl,
	FormLabel,
	Textarea,
	TextareaProps,
	FormHelperText,
	FormControlProps,
	FormErrorMessage
} from '@chakra-ui/react';

interface Props {
	name: string;
	label: string;
	error?: string;
	helperText?: string;
	formControlProps?: FormControlProps;
	textareaProps?: TextareaProps;
	onBlur?: () => void;
}

const TextControl: React.FC<Props> = (props) => {
	const { name, label, error, helperText, formControlProps, textareaProps, onBlur } = props;

	return (
		<FormControl marginBottom="4" {...formControlProps} isInvalid={!!error}>
			<FormLabel htmlFor={name}>{label}</FormLabel>
			<Textarea
				id={name}
				{...textareaProps}
				onBlur={(event) => {
					if (textareaProps && textareaProps.onBlur) {
						textareaProps.onBlur(event);
					}

					if (onBlur) {
						onBlur();
					}
				}}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default TextControl;
