export const Card = {
	baseStyle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		background: 'background.100',
		borderColor: 'border.100',
		borderWidth: '1px',
		gap: 6,
		marginBottom: 10
	},
	variants: {
		rounded: {
			padding: 10,
			borderRadius: 'xl',
			boxShadow: 'xl'
		},
		roundedNoPadding: {
			padding: 0,
			borderRadius: 'xl',
			boxShadow: 'xl'
		}
	},
	defaultProps: {
		variant: 'rounded'
	}
};
