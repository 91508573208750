import React, { useState } from 'react';
import {
	Select,
	HStack,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	IconButton
} from '@chakra-ui/react';
import { MdDone } from 'react-icons/md';

import { range } from 'utils';

interface Props {
	time: string;
	setTime: (value: string) => void;
}

const TimePicker: React.FC<Props> = (props) => {
	const { children, time, setTime } = props;

	const hours = range(1, 12).map((h) => String(h).padStart(2, '0'));
	const minutes = range(0, 59).map((m) => String(m).padStart(2, '0'));
	const periods = ['AM', 'PM'];

	const timeRegex = /(\d{2}):(\d{2}) (AM|PM)/;
	const parsedTimeObject = time.match(timeRegex);

	const [hour, setHour] = useState(parsedTimeObject ? parsedTimeObject[1] : '11');
	const [minute, setMinute] = useState(parsedTimeObject ? parsedTimeObject[2] : '59');
	const [period, setPeriod] = useState(parsedTimeObject ? parsedTimeObject[3] : 'PM');

	return (
		<Popover
			isLazy
			onClose={() => {
				setTime(`${hour}:${minute} ${period}`);
			}}>
			{({ onClose }) => (
				<>
					<PopoverTrigger>{children}</PopoverTrigger>
					<PopoverContent w="240px">
						<PopoverArrow />
						<PopoverBody>
							<HStack>
								<Select
									variant="flushed"
									size="sm"
									value={hour}
									onChange={(event) => setHour(event.target.value)}>
									{hours.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</Select>
								<Select
									variant="flushed"
									size="sm"
									value={minute}
									onChange={(event) => setMinute(event.target.value)}>
									{minutes.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</Select>
								<Select
									variant="flushed"
									size="sm"
									value={period}
									onChange={(event) => setPeriod(event.target.value)}>
									{periods.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</Select>
								<IconButton
									aria-label="Done"
									icon={<MdDone />}
									variant="outline"
									size="xs"
									onClick={onClose}
								/>
							</HStack>
						</PopoverBody>
					</PopoverContent>
				</>
			)}
		</Popover>
	);
};

export default TimePicker;
