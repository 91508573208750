export const Tag = {
	baseStyle: {
		container: {
			background: 'background.100',
			borderWidth: '1px',
			borderColor: 'rgba(0, 0, 0, 0.4)',
			boxShadow: 'none',
			py: '2'
		}
	},
	variants: {
		outline: {
			container: {
				color: 'black',
				borderRadius: 'sm'
			}
		}
	},
	defaultProps: {
		variant: 'outline',
		size: 'lg'
	}
};
