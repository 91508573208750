import React from 'react';
import { Box, Divider, HStack, Link } from '@chakra-ui/react';

const BrandFooter: React.FC = () => {
	return (
		<Box as="footer" py="12" px={{ base: '4', md: '8' }}>
			<Divider w="100%" borderBottomColor="gray.500" />
			<HStack justifyContent="center" alignContent="center" spacing="8" pt="8">
				<Link variant="noBorder" mr="8" href="https://www.soomolearning.com/support">
					Contact Support
				</Link>
				<Link variant="noBorder" href="https://www.soomolearning.com/adoption-faq">
					Course Adoption FAQ
				</Link>
			</HStack>
		</Box>
	);
};

export default BrandFooter;
