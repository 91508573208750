import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useGetTextbooksQuery } from 'store/api';
import { CreateCourseFormGlobalState } from 'types';

interface Props {
	setValue: UseFormSetValue<CreateCourseFormGlobalState>;
	willThisCourseBeIntegratedWithAnLMS?: string;
	willYouAssignDueDates?: string;
}

export function useIntegrations(props: Props): void {
	const { setValue, willThisCourseBeIntegratedWithAnLMS, willYouAssignDueDates } = props;
	const history = useHistory();
	const { data: textbooks } = useGetTextbooksQuery();

	/**
	 * Reset form options inside IntegrationsForm when LMS integration option changes
	 */
	useEffect(() => {
		if (willThisCourseBeIntegratedWithAnLMS === 'No') {
			setValue('whichLMS', '');
			setValue('willYouAssignDueDates', 'No');
			setValue('willYouAssignAPenaltyPeriod', 'No');
		}
	}, [setValue, willThisCourseBeIntegratedWithAnLMS]);

	/**
	 * Reset form options inside IntegrationsForm when assign due dates changes
	 */
	useEffect(() => {
		if (willYouAssignDueDates === 'No') {
			setValue('willYouAssignAPenaltyPeriod', 'No');
		}
	}, [setValue, willYouAssignDueDates]);

	/**
	 * Update webtext value when the user uses the Change Webtext modal
	 */
	useEffect(() => {
		if (history.location.pathname.includes('/preview')) {
			setValue(
				'webtext',
				Number(
					textbooks?.find(
						(textbook) => String(textbook.id) === history.location.pathname.replace('/preview/', '')
					)?.id
				)
			);
		}
	}, [history.location.pathname, setValue, textbooks]);
}
