import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Text,
	HStack,
	VStack,
	Spinner,
	Link,
	Divider,
	Heading,
	Box,
	SimpleGrid,
	ListItem,
	OrderedList,
	Tooltip,
	List,
	ListIcon
} from '@chakra-ui/react';
import { MdErrorOutline } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { ChapterTable } from 'components/controls';
import { useAppSelector } from 'store';
import { useGetTextbooksQuery, useUpdateCourseSubmissionMutation } from 'store/api';
import {
	CreateCourseFormGlobalState,
	CreateCourseFormKey,
	FormDataStateElementKey,
	YourInformationStateKeys,
	BasicCourseDetailsStateKeys,
	TermInformationStateKeys,
	IntegrationsStateKeys
} from 'types';
import { camelToTitleCase, displayBoolean, findSection, formatDate } from 'utils';
import { selectCourseId } from 'store/slices/contentSlice';
import { useSelfServeConfig, useTrackEvent } from 'hooks';
import { selectChapterOrganization } from 'store/slices/chapterOrganizationSlice';

type DisplaySection = { title: string; content: Record<string, unknown> };

const modalCopy = {
	title: 'Confirm your course information',
	subtitle: `If everything looks good, press the "Confirm and send" button to share with Soomo's course specialists.`
};

const dateKeys = ['studentAccessDate', 'termStartDate', 'termEndDate'];

const keyAliasMap: Record<string, string> = {
	willYouAssignAPenaltyPeriod: 'Will You Assign a Grace Period',
	privateTermName: 'Term Name'
};

const FormDataDisplaySection: React.FC<DisplaySection> = (props) => (
	<Box mb="12">
		<Text fontSize="2xl" fontWeight="bold" mb="2">
			{camelToTitleCase(props.title)}
		</Text>
		{Object.keys(props.content)
			.filter((k) => k !== 'status' && props.content[k] !== '')
			.map((key) => (
				<Box key={key} mb="4">
					<Text>{keyAliasMap[key] ? keyAliasMap[key] : camelToTitleCase(key)}</Text>
					{!dateKeys.includes(key) ? (
						<Text fontWeight="bold">{displayBoolean(String(props.content[key]))}</Text>
					) : (
						<Text fontWeight="bold">{formatDate(String(props.content[key]))}</Text>
					)}
				</Box>
			))}
	</Box>
);

interface Props {
	disabled?: boolean;
	errors?: string[];
	state: CreateCourseFormGlobalState;
	submitForm: () => Promise<unknown>;
	trigger: () => Promise<boolean>;
}

const ConfirmationModal: React.FC<Props> = (props) => {
	const { disabled, errors, state, submitForm, trigger } = props;

	const courseId = useAppSelector(selectCourseId);
	const { data: textbooks } = useGetTextbooksQuery();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const history = useHistory();
	const trackEvent = useTrackEvent();
	const chapterOrganizationState = useAppSelector(selectChapterOrganization);
	const chapterOrganizationStatus = chapterOrganizationState.isLocked ? 'complete' : 'incomplete';

	const [updateCourseSubmission, { isLoading }] = useUpdateCourseSubmissionMutation();

	const displaySections: CreateCourseFormKey[] = [
		'yourInformation',
		'basicCourseDetails',
		'termInformation',
		'integrations'
	];

	const groupedState = displaySections.map<DisplaySection>((sectionKey) => {
		const getSectionKeys = () => {
			switch (sectionKey) {
				case 'yourInformation':
					return YourInformationStateKeys.reduce((section, currentKey) => {
						return { ...section, [currentKey]: state[currentKey] };
					}, {});
				case 'basicCourseDetails':
					return BasicCourseDetailsStateKeys.reduce((section, currentKey) => {
						if (currentKey === 'webtext') {
							const textbookId = state[currentKey];
							return {
								...section,
								[currentKey]: textbooks?.find((t) => t.id === textbookId)?.externalName
							};
						}
						return { ...section, [currentKey]: state[currentKey] };
					}, {});
				case 'termInformation':
					return TermInformationStateKeys.reduce((section, currentKey) => {
						if (state[currentKey] && currentKey !== 'exampleTermName') {
							return { ...section, [currentKey]: state[currentKey] };
						}
						return section;
					}, {});
				case 'integrations':
					return IntegrationsStateKeys.reduce((section, currentKey) => {
						/**
						 * Do not display `pointsPerChapter` when the user has decided to not
						 * integrate with an LMS
						 */
						const { willThisCourseBeIntegratedWithAnLMS } = state;
						if (
							!willThisCourseBeIntegratedWithAnLMS ||
							(willThisCourseBeIntegratedWithAnLMS === 'No' && currentKey === 'pointsPerChapter')
						) {
							return section;
						}

						return { ...section, [currentKey]: state[currentKey] };
					}, {});
				default:
					return {};
			}
		};

		return {
			title: camelToTitleCase(sectionKey),
			content: getSectionKeys()
		};
	});

	const { isCustomizable } = useSelfServeConfig();

	const renderModalActionButtons = () => (
		<HStack justify="flex-end" align="center">
			<Link color="black" borderColor="black" mb="0" mr="3" onClick={onClose}>
				Go back and keep editing
			</Link>
			<Button
				id="confirmation-modal-confirm-and-send"
				mb="0"
				onClick={() => {
					submitForm().then(() => {
						updateCourseSubmission(courseId).then(() => {
							/**
							 * Track when the user submits the data after the <ConfirmationModal>
							 */
							trackEvent('review-form-submitted', { course_id: courseId });
							onClose();
							history.push(`/under-review/${courseId}`);
						});
					});
				}}>
				Confirm and send
			</Button>
		</HStack>
	);

	const renderErrors = () => {
		if (errors || chapterOrganizationStatus !== 'complete') {
			return (
				<List>
					{chapterOrganizationStatus === 'incomplete' && (
						<ListItem>
							<ListIcon as={MdErrorOutline} verticalAlign="text-top" fontSize="1.25em" />
							You must confirm chapter order
						</ListItem>
					)}
					{errors &&
						errors.map((e) => (
							<ListItem key={e} mb="4">
								<Text fontWeight="black">
									{findSection(e as FormDataStateElementKey)
										? `${camelToTitleCase(String(findSection(e as FormDataStateElementKey)))}: `
										: ''}
								</Text>
								{camelToTitleCase(e)}
							</ListItem>
						))}
				</List>
			);
		}
	};

	return (
		<Tooltip
			hasArrow
			label={errors ? renderErrors() : ''}
			bg="red.600"
			p="4"
			isDisabled={errors?.length === 0}>
			<Box w={['100%', 'unset']}>
				<Button
					w={['100%', 'unset']}
					onClick={() => {
						trigger().then((result) => {
							if (result) {
								trackEvent('review-form-confirmation-displayed');
								onOpen();
							}
						});
					}}
					disabled={disabled}>
					{props.children}
				</Button>
				<Modal size="5xl" isOpen={isOpen} onClose={onClose} id="confirmation-modal">
					<ModalOverlay />
					<ModalContent p={[2, 6]} mt={['0', '16']} gridGap="2">
						<ModalHeader>
							<Heading>{modalCopy.title}</Heading>
							<Text fontSize={['md', 'sm']} fontWeight="normal" mb="6">
								{modalCopy.subtitle}
							</Text>
							{renderModalActionButtons()}
						</ModalHeader>

						<ModalCloseButton />

						<ModalBody>
							{isLoading && (
								<VStack justifyContent="center" h="container.sm">
									<Spinner size="xl" />
								</VStack>
							)}

							{!isLoading && (
								<>
									<Divider borderColor="black" mb="8" />
									<SimpleGrid columns={2}>
										{groupedState.map((s) => (
											<FormDataDisplaySection key={s.title} title={s.title} content={s.content} />
										))}
									</SimpleGrid>

									<Text fontSize="2xl" fontWeight="bold" mb="2">
										{isCustomizable ? 'Chapter Organization' : 'Chapters'} &amp; Due Date
										Information
									</Text>

									<ChapterTable readOnly />

									{chapterOrganizationState.removedChapters.length > 0 && (
										<>
											<Text fontWeight="bold" mt="8" mb="4">
												Removed Chapters
											</Text>
											<OrderedList listStyleType="none" spacing="3" fontSize="sm">
												{chapterOrganizationState.removedChapters.map((removedChapter, index) => (
													<ListItem
														key={`removed-chapter-${index}`}>{`${removedChapter.chapterNumber}: ${removedChapter.chapterName}`}</ListItem>
												))}
											</OrderedList>
										</>
									)}

									<Text fontWeight="bold" mt="8" mb="1">
										Additional information for Soomo course specialists
									</Text>

									{state.note ? (
										<Text fontWeight="normal">{state.note}</Text>
									) : (
										<Text fontStyle="italic">Not supplied</Text>
									)}

									<Divider borderColor="black" mt="8" mb="4" />
								</>
							)}
						</ModalBody>

						<ModalFooter>{renderModalActionButtons()}</ModalFooter>
					</ModalContent>
				</Modal>
			</Box>
		</Tooltip>
	);
};
export default ConfirmationModal;
