import { useEffect } from 'react';

import { Chapter } from 'types';
import { defaultDueDate, translateDateToTimeZone } from 'utils';
import { useAppSelector, useAppDispatch } from 'store';
import {
	selectChapterOrganization,
	updateChapterOrganizationState
} from 'store/slices/chapterOrganizationSlice';

interface Props {
	termStartDate?: string;
	termEndDate?: string;
	pointsPerChapter?: number;
	timeZone: string;
}

export function useChapterOrganization(props: Props): void {
	const { termStartDate, termEndDate, pointsPerChapter, timeZone } = props;

	const dispatch = useAppDispatch();
	const state = useAppSelector(selectChapterOrganization);

	function updateAllChapters(mutation: Partial<Chapter>) {
		dispatch(
			updateChapterOrganizationState({
				...state,
				chapters: state.chapters.map((c) => ({ ...c, ...mutation }))
			})
		);
	}

	function updateAllDueDates(mutation: { dueDate?: string }) {
		dispatch(
			updateChapterOrganizationState({
				...state,
				chapters: state.chapters.map((c) => ({
					...c,
					dueDate: translateDateToTimeZone(mutation.dueDate || '', timeZone)
				}))
			})
		);
	}

	/**
	 * Pre-populate chapter due date when term dates are modified
	 */
	useEffect(() => {
		if (!termStartDate) return;
		updateAllDueDates({ dueDate: defaultDueDate(termStartDate, termEndDate) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [termStartDate, termEndDate]);

	/**
	 * Update chapter.points when `pointsPerChapter` changes
	 */
	useEffect(() => {
		if (pointsPerChapter) {
			updateAllChapters({ points: pointsPerChapter });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pointsPerChapter]);
}
