import React from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputProps,
	List,
	ListItem
} from '@chakra-ui/react';
import { ControllerRenderProps } from 'react-hook-form';

import { useTypeahead } from '@soomo/lib/hooks';
import { School } from 'types';

interface Props {
	name: string;
	label: string;
	error?: string;
	items?: string[];
	school?: School;
	field: ControllerRenderProps;
	setValue: (value: string) => void;
}

const TypeaheadControl: React.FC<Props> = (props) => {
	const { name, label, error, items, school, field, setValue } = props;

	const value = field.value;

	const {
		isOpen,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		getItemProps,
		highlightedIndex,
		inputItemsToDisplay
	} = useTypeahead({ name, label, value, setValue, items });

	const barredSchoolErrorMessage = `Contact sales to create a new course for ${school?.name}`;

	const inputProps = getInputProps() as InputProps;

	return (
		<FormControl marginBottom="4" isInvalid={(school && !school?.selfServe) || !!error}>
			<FormLabel htmlFor={name} {...getLabelProps()}>
				{label}
			</FormLabel>

			<InputGroup {...getComboboxProps()}>
				<Input
					{...inputProps}
					id={name}
					className="typeahead-input"
					onBlur={field.onBlur}
					value={inputProps.value || field.value}
				/>
			</InputGroup>

			<List
				{...getMenuProps()}
				spacing="2"
				mt="-1"
				pt="1"
				border={isOpen ? '1px #e2e8f0 solid' : ''}
				borderTop="none"
				className="typeahead-list">
				{isOpen &&
					inputItemsToDisplay.map((item, index) => (
						<ListItem
							mt={index === 0 ? '1' : ''}
							p="2"
							key={`${item}-${index}`}
							className="typeahead-list-item"
							{...getItemProps({
								key: item,
								index,
								item,
								style: {
									backgroundColor: highlightedIndex === index ? '#eeeeee' : 'white'
								}
							})}>
							{item}
						</ListItem>
					))}
			</List>

			<FormErrorMessage>
				{school && !school?.selfServe ? barredSchoolErrorMessage : error}
			</FormErrorMessage>
		</FormControl>
	);
};

export default TypeaheadControl;
