import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider, ErrorBoundary } from '@rollbar/react';

import theme from 'theme';
import { SelfServe } from 'components';
import { Error } from 'components/pages';
import { persistor } from 'store';

function App(): JSX.Element {
	const Fallback = () => (
		<ChakraProvider theme={theme}>
			<Error />
		</ChakraProvider>
	);

	return (
		<Provider
			config={{
				accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
				environment: process.env.NODE_ENV
			}}>
			<ErrorBoundary fallbackUI={Fallback}>
				<ChakraProvider theme={theme}>
					<PersistGate loading={null} persistor={persistor}>
						<Router>
							<SelfServe />
						</Router>
					</PersistGate>
				</ChakraProvider>
			</ErrorBoundary>
		</Provider>
	);
}

export default App;
