import React from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	InputProps,
	FormHelperText,
	FormControlProps,
	FormErrorMessage
} from '@chakra-ui/react';

interface Props {
	name: string;
	label: string;
	error?: string;
	helperText?: string;
	formControlProps?: FormControlProps;
	inputProps?: InputProps;
}

const TextControl: React.FC<Props> = (props) => {
	const { name, label, error, helperText, formControlProps, inputProps } = props;

	const isReadOnly = inputProps?.isReadOnly;

	return (
		<FormControl
			marginBottom="4"
			{...formControlProps}
			isInvalid={!!error}
			color={isReadOnly ? 'gray.600' : 'initial'}>
			<FormLabel htmlFor={name}>{label}</FormLabel>
			<Input
				id={name}
				{...inputProps}
				cursor={isReadOnly ? 'no-drop' : 'initial'}
				userSelect={isReadOnly ? 'none' : 'initial'}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default TextControl;
