import React from 'react';
import { Box, Image } from '@chakra-ui/react';

const BrandHeader: React.FC = () => {
	return (
		<Box as="nav" px="28" py="16">
			<Image src={process.env.PUBLIC_URL + '/logo.svg'} alt="Soomo Logo" />
		</Box>
	);
};

export default BrandHeader;
