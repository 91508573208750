import React from 'react';
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Icon,
	Stack,
	Text
} from '@chakra-ui/react';
import { FaCheckCircle, FaRegCircle, FaLock } from 'react-icons/fa';

import { CreateCourseFormSectionStatus } from 'types';

interface Props {
	title: string;
	helpText?: string;
	status: CreateCourseFormSectionStatus;
}

const SetupAccordionItem: React.FC<Props> = (props) => {
	const { title, helpText, status, children } = props;

	const sectionIcon =
		status === 'complete' ? FaCheckCircle : status === 'locked' ? FaLock : FaRegCircle;

	return (
		<AccordionItem
			isDisabled={status === 'locked'}
			id={title
				.replace(/[_\s]+/g, '-')
				.replace('&', 'and')
				.toLowerCase()}>
			<h2>
				<AccordionButton
					fontSize="xl"
					py="6"
					data-test-state={status}
					flexDirection={['column', 'row']}
					gridGap={[6, 0]}
					alignItems="center">
					<Stack
						flex="1"
						direction="row"
						spacing="3"
						w="100%"
						justify={['space-between', 'flex-start']}>
						<Icon color="brandBlue.500" boxSize={['1.3em', '1.25em']} as={sectionIcon} />
						<Text textAlign="left" fontWeight="semibold" fontSize={['md', 'lg']}>
							{title}
						</Text>
						<AccordionIcon display={['inline-block', 'none']} />
					</Stack>

					{helpText && status !== 'complete' && (
						<Text fontStyle="italic" fontSize="sm">
							{helpText}
						</Text>
					)}
					<AccordionIcon ml="2" display={['none', 'inline-block']} />
				</AccordionButton>
			</h2>
			<AccordionPanel py={[4, 2]} px="8">
				{children}
			</AccordionPanel>
		</AccordionItem>
	);
};

export default SetupAccordionItem;
