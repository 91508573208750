import { extendTheme } from '@chakra-ui/react';

import { Button } from './Button';
import { Card } from './Card';
import { Link } from './Link';
import { Input } from './Input';
import { Select } from './Select';
import { Tag } from './Tag';
import { Table } from './Table';

const colors = {
	brand: {
		// Blues
		900: '#1a365d',
		800: '#666666',
		700: '#2a69ac',
		// Greens
		600: '#a1b728',
		500: '#bdd53d',
		// Purple
		400: '#7201eb'
	},
	brandBlue: {
		800: '#02018a',
		700: '#0302b8',
		500: '#0403e6',
		200: '#7779f1',
		100: '#9fa1f5'
	},
	background: {
		900: '#111111',
		800: '#444444',
		700: '#666666',
		600: '#cccccc',
		500: '#ebe9e9',
		200: '#f5f5f5',
		100: '#ffffff'
	},
	border: {
		900: '#111111',
		800: '#25292c',
		400: '#444444',
		300: '#666666',
		200: '#777777',
		100: '#979797'
	}
};

const styles = {
	global: {
		'html, body': {
			background: 'background.200',
			height: '100%'
		}
	}
};

const theme = extendTheme({
	fonts: {
		heading: 'TiemposHeadline',
		body: 'SuisseIntl'
	},
	colors,
	styles,
	components: {
		Button,
		Card,
		Link,
		Input,
		Select,
		Tag,
		Table
	}
});

export default theme;
