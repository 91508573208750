import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from 'store';
import { useWindowSize } from 'utils/hooks';
import { WebtextIframe } from 'components';
import { useGetTextbookReviewUrlQuery, useGetTextbooksQuery, useGetUserInfoQuery } from 'store/api';
import {
	updateGlobalState,
	selectVerified,
	updateVerified
} from 'store/slices/createCourseFormSlice';
import { useTrackExternalWebtext } from 'hooks';
import { InstructorApprovedModal } from 'components/modals';

const Preview: React.FC = () => {
	const dispatch = useAppDispatch();
	const windowSize = useWindowSize();
	const history = useHistory();
	const containerRef = useRef(document.getElementById('self-serve-header'));
	const [spacingToNegate, setSpacingToNegate] = useState(0);
	const verified = useAppSelector(selectVerified);

	const { textbookId } = useParams<{ textbookId?: string }>();
	const { data: textbookReviewUrl } = useGetTextbookReviewUrlQuery(Number(textbookId));
	const { data: textbooks, isSuccess: isTextbooksReady } = useGetTextbooksQuery();
	const { data: user } = useGetUserInfoQuery();

	/**
	 * This page contains an iframed Webtext, we need to track events it could send us.
	 */
	useTrackExternalWebtext({ textbookId: Number(textbookId) });

	/**
	 * If we are on this page without a `textbookId` in the url we need to get the first textbook
	 * from the available list and reenter the page with that `textbookId`
	 */
	useEffect(() => {
		if (!textbookId && isTextbooksReady && textbooks) {
			history.push(`/preview/${textbooks[0].id}`);
		}
	}, [history, isTextbooksReady, textbookId, textbooks]);

	/**
	 * Recalculate iframe size so it fills remaining screen space
	 */
	useEffect(() => {
		containerRef.current = document.getElementById('self-serve-header');
		if (containerRef.current) {
			setSpacingToNegate(containerRef.current.getBoundingClientRect().height);
		}
	}, [containerRef, windowSize]);

	/**
	 * Update textbookId when the route changes
	 */
	useEffect(() => {
		dispatch(updateGlobalState({ webtext: Number(textbookId) }));
	}, [dispatch, textbookId]);

	/**
	 * If the user refreshes the page, we need to look at the user object and see if they are newly verified
	 */
	useEffect(() => {
		if (!verified && user?.verified) {
			dispatch(updateVerified(true));
		}
	}, [dispatch, user, verified]);

	return (
		<VStack
			position="relative"
			zIndex="0"
			height={`calc(100vh - ${spacingToNegate}px)`}
			justifyContent="center">
			<WebtextIframe src={textbookReviewUrl || ''} />

			<InstructorApprovedModal />
		</VStack>
	);
};

export default Preview;
