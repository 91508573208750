import React, { memo, useEffect } from 'react';
import { VStack, Spinner } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import {
	FullStory,
	init as initFullStory,
	isInitialized as isFullStoryInitialized
} from '@fullstory/browser';
import { useRollbar } from '@rollbar/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { persistor, useAppDispatch } from 'store';
import { updateAccessToken, updateIntercomUserHash, updateUserId } from 'store/slices/contentSlice';
import {
	updateVerified,
	updateSchoolId,
	resetGlobalState
} from 'store/slices/createCourseFormSlice';
import {
	MARKETING_SITE_URL,
	SELF_SERVE_API,
	useGetTextbooksQuery,
	useLazyGetCoursesQuery,
	useLazyGetIntakeQuery,
	useLazyGetUserInfoQuery
} from 'store/api';
import { AccessTokenResponse } from 'types';
import { useTrackEvent } from 'hooks';
import { bootIntercom } from 'hooks/intercom';

const Welcome: React.FC = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const trackEvent = useTrackEvent();
	const rollbar = useRollbar();

	/**
	 * Query params...
	 *
	 * `jwt`		->	access token (required)
	 * `uuid`		->	intake uuid (optional)
	 * `verified`	->	verification status (optional)
	 * `returning`	->	is the user returning to the app (optional)
	 * `courseId`	->	courseId of the destination course, if this exists we should eventually
	 *					redirect the user to `/review/${courseId} (optional)
	 */
	const { searchParams } = new URL(window.location.href);
	const jwt = String(searchParams.get('jwt'));
	const uuid = searchParams.get('intake_uuid') as string;
	const verified = searchParams.get('verified') === 'true';
	const returning = searchParams.get('returning') === 'true';
	const courseId = searchParams.get('courseId') as string;

	const { data: textbooks } = useGetTextbooksQuery();
	const [getIntakeTrigger, getIntakeResult] = useLazyGetIntakeQuery();
	const [getUserInfoTrigger, getUserInfoResult] = useLazyGetUserInfoQuery();
	const [getCoursesTrigger, getCoursesResponse] = useLazyGetCoursesQuery();

	/**
	 * @todo New / Returning Users
	 *
	 * Maybe in the future we should have another URL parameter that designates whether the user is
	 * entering the app for the first time or they are returning.  We could then attempt to redirect
	 * them to the last page they were visiting if they were a returning user.
	 */

	/**
	 * Purge contents of redux-persist storage, reloading /welcome is basically logging out and
	 * logging in again.  We know we will have (WE DEFINITELY SHOULD) a fresh OTU jwt in the URL
	 * of this page so the first thing we should do is get a new access token.  After the new token
	 * is retrieved we then trigger getting intake data.
	 */
	useEffect(() => {
		/**
		 * Redirect user to marketing site if there is no JWT in the query params
		 *
		 * It is string value of "null" because of the casting above
		 */
		if (jwt === 'null') {
			window.location.href = String(MARKETING_SITE_URL);
			return;
		}

		persistor.purge().then(() => {
			dispatch(updateVerified(verified));

			fetch(SELF_SERVE_API.ACCESS_TOKEN, {
				headers: {
					Authorization: `Bearer ${jwt}`
				}
			})
				.then((response) => response.json())
				.then((response: AccessTokenResponse) => {
					dispatch(updateAccessToken(response.token));
					dispatch(updateIntercomUserHash(response.intercom_user_hash));
				})
				.then(() => {
					if (!returning) {
						getIntakeTrigger(uuid);
					} else {
						/**
						 * Trigger the calls needed for rehydration since the user is returning
						 */
						getUserInfoTrigger();
					}
				})
				.catch(rollbar.error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Once we have retrieved intake data, local state should be updated and we should redirect the
	 * user to the corresponding /preview page based on their intake data.
	 */
	useEffect(() => {
		if (getIntakeResult.isSuccess && getIntakeResult.data) {
			const {
				firstName,
				lastName,
				email,
				schoolName,
				schoolId,
				textbookId,
				userId,
				intercomUserHash,
				fullstoryEnabled,
				fullstoryProperties
			} = getIntakeResult.data;

			/**
			 * Make sure Intercom knows we a new user has logged in
			 */
			bootIntercom({
				firstName,
				lastName,
				email,
				userId,
				userHash: intercomUserHash
			});

			if (process.env.REACT_APP_FULLSTORY_ORG_ID && fullstoryEnabled && !isFullStoryInitialized()) {
				initFullStory({
					orgId: process.env.REACT_APP_FULLSTORY_ORG_ID
				});
				FullStory('setIdentity', {
					uid: String(userId),
					properties: fullstoryProperties
				});
			}

			/**
			 * Reset global state with intake data
			 */
			dispatch(
				resetGlobalState({
					firstName,
					lastName,
					email,
					schoolName,
					webtext: textbookId
				})
			);

			dispatch(updateUserId(userId));
			dispatch(updateSchoolId(schoolId));

			trackEvent('webtext-preview-launched', {
				from: 'completed-email-validation',
				textbook_id: textbookId
			});

			history.push(`/preview/${textbookId}`);
		}
	}, [dispatch, getIntakeResult.data, getIntakeResult.isSuccess, history, textbooks, trackEvent]);

	/**
	 * Once we have retrieved the data from `/user_info`, local state should be updated and we
	 * should redirect the user to `/preview` or `/review`.
	 */
	useEffect(() => {
		/**
		 * Rehydrate the store with what we have been provided by `/user_info`
		 */
		if (getUserInfoResult.isSuccess && getUserInfoResult.data) {
			const {
				firstName,
				lastName,
				email,
				schoolId,
				schoolName,
				verified,
				textbookId,
				userId,
				intercomUserHash,
				fullstoryEnabled,
				fullstoryProperties
			} = getUserInfoResult.data;

			/**
			 * Make sure Intercom knows we a new user has logged in
			 */
			bootIntercom({
				firstName,
				lastName,
				email,
				userId,
				userHash: intercomUserHash
			});

			if (process.env.REACT_APP_FULLSTORY_ORG_ID && fullstoryEnabled && !isFullStoryInitialized()) {
				initFullStory({
					orgId: process.env.REACT_APP_FULLSTORY_ORG_ID
				});
				FullStory('setIdentity', {
					uid: String(userId),
					properties: fullstoryProperties
				});
			}

			dispatch(updateUserId(userId));
			dispatch(updateSchoolId(schoolId));
			dispatch(updateVerified(verified));
			dispatch(
				resetGlobalState({
					firstName,
					lastName,
					email,
					schoolName,
					webtext: textbookId
				})
			);

			/**
			 * When there is no `courseId` parameter, the user did not have a course in `draft`
			 * state, so we will redirect them to `/preview` so they can begin creating a new course.
			 * If a `textbookId` is available then we should use that textbook by default
			 *
			 * When we have a `courseId` parameter, we should trigger the `/courses` endpoint.
			 */
			if (!courseId) {
				if (textbookId) {
					trackEvent('webtext-preview-launched', {
						from: 'sign-in-email',
						textbook_id: textbookId
					});
					history.push(`/preview/${textbookId}`);
				} else {
					trackEvent('webtext-preview-launched', {
						from: 'sign-in-email'
					});
					history.push(`/preview`);
				}
			} else {
				getCoursesTrigger();
			}
		}
	}, [
		courseId,
		dispatch,
		getCoursesTrigger,
		getUserInfoResult.data,
		getUserInfoResult.isSuccess,
		history,
		textbooks,
		trackEvent
	]);

	/**
	 * The user is returning to the app from a magic link and the server has determined they
	 * had previously created a course.
	 */
	useEffect(() => {
		/**
		 * Check course's status, if it not `draft` then redirect user to `/under-review/:courseId`,
		 * if it is draft redirect the user to `/review/:courseId`.
		 */
		if (getCoursesResponse.isSuccess && getCoursesResponse.data) {
			const providedCourse = getCoursesResponse.data.find((c) => c.id === Number(courseId));

			if (!(providedCourse?.self_serve_status === 'draft')) {
				history.push(`/under-review/${courseId}`);
			} else {
				history.push(`/review/${courseId}`);
			}
		}
	}, [courseId, getCoursesResponse.data, getCoursesResponse.isSuccess, history]);

	/**
	 * Make sure that the user does not get stuck infinitely spinning in the case of an error
	 * we can't recover from.
	 */
	useEffect(() => {
		if (getUserInfoResult.error) {
			rollbar.error('Could not GET /user_info', getUserInfoResult.error as FetchBaseQueryError);
			history.push(`/error`);
		}
	}, [getUserInfoResult.error, getUserInfoResult.isError, history, rollbar]);

	return (
		<VStack justifyContent="center" h="100%">
			<Spinner size="xl" />
		</VStack>
	);
};

export default memo(Welcome);
