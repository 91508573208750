import { useRef } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { diff } from 'deep-object-diff';

import { useAppSelector } from 'store';
import { useTrackEvent } from 'hooks';
import { getYearFromDate } from 'utils';
import { useGetCourseReviewQuery, useUpdateCourseReviewMutation } from 'store/api';
import {
	CreateCourseFormGlobalState,
	CourseReviewRequest,
	School,
	ChapterOrganizationState
} from 'types';
import { selectDefaults } from 'store/slices/defaultsSlice';

interface Props {
	courseId: number;
	school: School;
	chapterOrganizationState: ChapterOrganizationState;
}

interface SyncConfig {
	lockChapterOrganization?: boolean;
	submit?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSyncWithServer(props: Props) {
	const { courseId, school, chapterOrganizationState } = props;

	const trackEvent = useTrackEvent();
	const isInitialSync = useRef(true);
	const { getValues, control } = useFormContext<CreateCourseFormGlobalState>();
	const { isValid } = useFormState({ control });
	const defaults = useAppSelector(selectDefaults);

	const [updateCourseReview, { isError: isErrorMutation, isLoading: isLoadingMutation }] =
		useUpdateCourseReviewMutation();
	const {
		data: courseReviewState,
		isSuccess: isSuccessCourseReviewState,
		isError: isErrorQuery
	} = useGetCourseReviewQuery(courseId);

	const syncWithServer = (config?: SyncConfig) => {
		const values = getValues();

		const termNameFromExample = `${values.exampleTermName} ${getYearFromDate(
			`${values.termStartDate}`
		)}`;

		/**
		 * Set `privateTermName` equal to the templated output from `exampleTermName` so we don't
		 * need to deal with both over time. When the users has not selected `I'm not sure` we
		 * should never have a value set for `exampleTermName`.
		 */
		if (values.whenWillYouTeachThisCourse === `I’m not sure`) {
			values.privateTermName = termNameFromExample;
		} else {
			values.exampleTermName = '';
		}

		/**
		 * Never send blank string values for dates to the server
		 */
		if (!values.termStartDate) {
			delete values.termStartDate;
		}

		if (!values.termEndDate) {
			delete values.termEndDate;
		}

		if (!values.studentAccessDate) {
			delete values.studentAccessDate;
		}

		const payload: CourseReviewRequest = {
			courseId: courseId,
			request: {
				user: {
					first_name: values.firstName,
					last_name: values.lastName
				},
				course: {
					name: values.courseName,
					number: values.courseSectionNumber,
					textbook_id: values.webtext,
					school_id: school.id,
					school_name: school.name,
					self_serve_options: {
						global: values,
						chapterOrganization: {
							...chapterOrganizationState,
							...(typeof config?.lockChapterOrganization === 'boolean' && {
								isLocked: config.lockChapterOrganization
							})
						},
						defaults
					}
				}
			}
		};

		/**
		 * Check for difference between latest fetched state from server and current state of the
		 * form before sending a new update to the server.
		 */
		if (courseReviewState) {
			/**
			 * Do not use `academicTermId` and `termName` in the diff if `privateTermName` is in the payload.
			 * The two previously mentioned fields will always be undefined if `privateTermName` is speicifed.
			 */
			if (payload.request.course.self_serve_options.global.privateTermName) {
				delete payload.request.course.self_serve_options.global.academicTermId;
				delete payload.request.course.self_serve_options.global.termName;
			}
			
			const updatedGlobalState = diff(
				courseReviewState?.course.selfServeOptions.global,
				payload.request.course.self_serve_options.global
			);

			const chapOrgDiff = diff(
				courseReviewState?.course.selfServeOptions.chapterOrganization,
				payload.request.course.self_serve_options.chapterOrganization
			);

			/**
			 * When no differences are detected we should not send an update to the server
			 */
			if (Object.keys(updatedGlobalState).length === 0 && Object.keys(chapOrgDiff).length === 0) {
				return Promise.resolve();
			}
		}

		return updateCourseReview(payload).then(async (result) => {
			/**
			 * If this sync is not a submission
			 */
			if (!config?.submit) {
				/**
				 * We don't want to send an event out if it is the initial sync with the server to
				 * initialize the values kept in `self_serve_options`. If it is not the initial sync
				 * then we want to track this as a update because it means the user has changed a
				 * value on the form.
				 */
				if (isInitialSync.current) {
					isInitialSync.current = false;
				} else {
					if (courseReviewState) {
						/**
						 * Check if textbook has changed in since the last sync
						 */
						if (courseReviewState.course.selfServeOptions?.global?.webtext !== values.webtext) {
							trackEvent('review-form-updated', {
								valid: isValid,
								textbook_id: values.webtext
							});
						} else {
							trackEvent('review-form-updated', {
								valid: isValid
							});
						}
					}
				}
			}

			return result;
		});
	};

	return {
		syncWithServer,
		courseReviewState,
		isSuccessCourseReviewState,
		isErrorMutation,
		isLoadingMutation,
		isErrorQuery
	} as const;
}
