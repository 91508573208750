import React from 'react';
import { chakra } from '@chakra-ui/react';

import { DatePicker, TimePicker } from 'components';
import { Chapter } from 'types';
import { translateDateToTimeZone, getMinMax } from 'utils';
import { DDTRowFieldConfig } from './types';

interface Props {
	row: Omit<Chapter, 'chapterFamilyId'>;
	rowKey: keyof Omit<Chapter, 'chapterFamilyId'>;
	rowValue: string | number;
	rowConfig: DDTRowFieldConfig;
	timeZone: string;
	updateChapterState: (chapterNumber: number, update: Partial<Chapter>) => void;
}

const ChapterTableCell: React.FC<Props> = (props) => {
	const { row, rowKey, rowValue, rowConfig, timeZone, updateChapterState } = props;

	const cellId = `row-${row.chapterNumber}-${rowKey}`;

	if (typeof row[rowKey] === 'number') {
		return (
			<chakra.input
				id={cellId}
				border="solid 1px #979797"
				rounded="sm"
				p="1"
				textAlign={rowConfig.textAlign}
				type="number"
				min={rowConfig.minimumValue || getMinMax(rowConfig.characterLimit).min}
				max={getMinMax(rowConfig.characterLimit).max}
				value={rowValue > 0 ? rowValue.toString() : ''}
				onChange={(event) => {
					updateChapterState(row.chapterNumber, {
						[rowKey]: Number(event.target.value)
					});
				}}
			/>
		);
	} else if (rowConfig.isDate) {
		return (
			<DatePicker
				id={cellId}
				simpleInput
				characterLimit={rowConfig.characterLimit}
				date={String(rowValue)}
				setDate={(date) => {
					/**
					 * Embed `dueTime` in `dueDate`
					 */
					updateChapterState(row.chapterNumber, {
						[rowKey]: translateDateToTimeZone(date, timeZone)
					});
				}}
				maxDate={rowConfig.maxDate}
			/>
		);
	} else if (rowConfig.isTime) {
		return (
			<TimePicker
				time={String(rowValue)}
				setTime={(value) => {
					/**
					 * Embed `dueTime` in `dueDate`
					 */
					updateChapterState(row.chapterNumber, {
						[rowKey]: value
					});
				}}>
				<chakra.input
					id={cellId}
					border="solid 1px #979797"
					rounded="sm"
					p="1"
					textAlign={rowConfig.textAlign}
					size={rowConfig.characterLimit}
					placeholder={rowConfig.placeholder}
					value={rowValue}
					onChange={(event) => {
						updateChapterState(row.chapterNumber, {
							[rowKey]: event.target.value
						});
					}}
				/>
			</TimePicker>
		);
	}

	return (
		<chakra.input
			id={cellId}
			border="solid 1px #979797"
			rounded="sm"
			p="1"
			textAlign={rowConfig.textAlign}
			value={rowValue}
			size={rowConfig.characterLimit}
			placeholder={rowConfig.placeholder}
			onChange={(event) => {
				updateChapterState(row.chapterNumber, {
					[rowKey]: event.target.value
				});
			}}
		/>
	);
};

export default ChapterTableCell;
