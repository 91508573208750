export const Table = {
	baseStyle: {
		th: {
			textTransform: 'none',
			letterSpacing: 'normal',
			fontFamily: 'body'
		}
	},
	sizes: {
		sm: {
			th: {
				textTransform: 'none',
				letterSpacing: 'normal',
				fontFamily: 'body',
				px: 2
			},
			td: {
				px: 2
			}
		},
		md: {
			td: {
				px: 2,
				py: 3
			}
		}
	}
};
