export const Select = {
	parts: ['field'],
	baseStyle: {
		borderRadius: 0,
		borderColor: 'border.400'
	},
	sizes: {
		lg: {
			field: {
				borderRadius: 0
			}
		}
	},
	defaultProps: {
		size: 'lg'
	}
};
