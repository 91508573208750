import React, { useEffect } from 'react';
import { Text, Button, Link, Icon } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FaTrash, FaTrashRestore, FaLock } from 'react-icons/fa';
import { MdArrowForward } from 'react-icons/md';
import { useRollbar } from '@rollbar/react';

import { ChapterOrganizationState, CreateCourseFormGlobalState } from 'types';
import { useAppSelector, useAppDispatch } from 'store';
import { DragAndDropItem, DragAndDropItemProps, ChaptersDragAndDrop } from 'components';
import { useGetTextbooksChaptersQuery, useGetTextbookReviewUrlQuery } from 'store/api';
import { selectSchool } from 'store/slices/createCourseFormSlice';
import { selectCourseId } from 'store/slices/contentSlice';
import { prepareChapters } from 'utils';
import {
	useSelfServeConfig,
	useSyncWithServer,
	useTrackEvent,
	useTrackExternalWebtext
} from 'hooks';
import { useChapterOrganization } from 'utils/hooks';
import { updateChapterInfo } from 'store/slices/defaultsSlice';
import {
	selectChapterOrganization,
	updateChapterOrganizationState
} from 'store/slices/chapterOrganizationSlice';

const ChapterOrganizationForm: React.FC = () => {
	const dispatch = useAppDispatch();
	const trackEvent = useTrackEvent();
	const rollbar = useRollbar();
	const courseId = useAppSelector(selectCourseId);
	const school = useAppSelector(selectSchool);
	const selfServeConfig = useSelfServeConfig();

	const state = useAppSelector(selectChapterOrganization);
	const { chapters, removedChapters, isLocked } = state;

	const { watch } = useFormContext<CreateCourseFormGlobalState>();
	const textbookId = Number(watch('webtext'));
	const watchTermStartDate = watch('termStartDate');
	const watchTermEndDate = watch('termEndDate');
	const watchPointsPerChapter = watch('pointsPerChapter');
	const watchAcademicTermId = watch('academicTermId');
	const watchWhenWillYouTeachThisCourse = watch('whenWillYouTeachThisCourse');

	const { syncWithServer, courseReviewState } = useSyncWithServer({
		courseId,
		school,
		chapterOrganizationState: state
	});

	const { data: textbookReviewUrl } = useGetTextbookReviewUrlQuery(textbookId);
	const { currentData: textbooksChapters, isSuccess: textbookChaptersSuccess } =
		useGetTextbooksChaptersQuery(textbookId);

	const updateState = (update: Partial<ChapterOrganizationState>) => {
		dispatch(updateChapterOrganizationState({ ...state, ...update }));
	};

	const buildDndItem = (action: 'remove' | 'unremove', isLocked: boolean) => {
		const DndItem: React.FC<DragAndDropItemProps> = (props) => {
			const icon = isLocked ? <FaLock /> : action === 'remove' ? <FaTrash /> : <FaTrashRestore />;
			return <DragAndDropItem {...props} icon={icon} removed={action !== 'remove'} />;
		};
		return DndItem;
	};

	/**
	 * Make sure that our form state is synced with the server and we have loaded textbook
	 * chapters.
	 */
	useEffect(() => {
		const selfServeOptions = courseReviewState?.course?.selfServeOptions;

		if (textbookChaptersSuccess && textbooksChapters) {
			/**
			 * These dates can be set from the form values or from the saved draft from the server
			 */
			let termStartDate: string | undefined;
			let termEndDate: string | undefined;

			if (state.chapters.length === 0) {
				/**
				 * This is the first time the user is entering the /review page. This will cover cases
				 * when en existing academic term is used and when a new one is created. We retrieve
				 * the dates from the form state, because a draft is most likely not saved to the
				 * server at this point.
				 */
				termStartDate = watchTermStartDate;
				termEndDate = watchTermEndDate;
			} else if (
				selfServeOptions &&
				selfServeOptions.global &&
				(selfServeOptions.chapterOrganization?.chapters.length === 0 ||
					textbookId !== courseReviewState?.textbook.id)
			) {
				/**
				 * Requests are loaded and we have chapters, we need to determine if new chapters need
				 * to be loaded or if we are using the correct chapters. If there are no chapters
				 * saved to the server or the local `textbookId` does not match what is saved on the
				 * server, build new chapters.
				 */
				termStartDate = selfServeOptions.global.termStartDate;
				termEndDate = selfServeOptions.global.termEndDate;
			}

			/**
			 * If one of the conditions above was true, we should rebuild chapters.  This is
			 * determined by `termStartDate` being set.
			 */
			if (termStartDate) {
				const nextChapterOrgState = prepareChapters(
					textbooksChapters,
					school.timeZone,
					termStartDate,
					termEndDate
				);

				dispatch(updateChapterOrganizationState(nextChapterOrgState));
				dispatch(updateChapterInfo(nextChapterOrgState.chapters));
			}
		}
	}, [
		courseReviewState,
		school.timeZone,
		state.chapters.length,
		textbookChaptersSuccess,
		textbookId,
		textbooksChapters,
		dispatch,
		watchAcademicTermId,
		watchTermStartDate,
		watchTermEndDate,
		school.id,
		rollbar,
		courseId,
		watchWhenWillYouTeachThisCourse
	]);

	useChapterOrganization({
		termStartDate: watchTermStartDate,
		termEndDate: watchTermEndDate,
		pointsPerChapter: watchPointsPerChapter,
		timeZone: school.timeZone
	});

	/**
	 * This page can open a Webtext in a new tab, we need to track events it could send us.
	 */
	useTrackExternalWebtext({ textbookId: Number(textbookId) });

	return (
		<>
			<Text mb="4">
				Drag and drop the chapters into your preferred order. Remove unassigned chapters by clicking
				the trash icons.
			</Text>

			{selfServeConfig.chapterExplanation && (
				<Text mb="4">{selfServeConfig.chapterExplanation}</Text>
			)}

			<Text mb="4">{`When you're finished, click the "I'm done" button below.`}</Text>

			<Link
				href={textbookReviewUrl}
				variant="external"
				target="_blank"
				rel="opener"
				onClick={() => {
					/**
					 * Track when the user wants to view webtext chapters.  This opens the
					 * `review_url` in a new tab.
					 */
					trackEvent('webtext-chapters-viewed', {
						textbook_id: textbookId
					});
				}}>
				View Webtext Chapters <Icon as={MdArrowForward} fontSize="1.3em" />
			</Link>

			<ChaptersDragAndDrop
				activeItems={chapters}
				setActiveItems={(chapters) => updateState({ chapters })}
				removedItems={removedChapters}
				setRemovedItems={(removedChapters) => updateState({ removedChapters })}
				setItems={(active, removed) => updateState({ chapters: active, removedChapters: removed })}
				onActiveItemActionClick={(indexToRemove) => {
					const itemToRemove = chapters[indexToRemove];

					updateState({
						chapters: chapters.filter((item, index) => index !== indexToRemove),
						removedChapters: [...removedChapters, itemToRemove]
					});
				}}
				onRemovedItemActionClick={(indexToUnremove) => {
					const itemToUnremove = removedChapters[indexToUnremove];

					updateState({
						removedChapters: removedChapters.filter((item, index) => index !== indexToUnremove),
						chapters: [...chapters, itemToUnremove]
					});
				}}
				activeItemComponent={buildDndItem('remove', isLocked)}
				removedItemComponent={buildDndItem('unremove', isLocked)}
				disabled={isLocked}
			/>

			<Button
				id="lock-chapter-organization"
				size="lg"
				mt="4"
				w={['100%', 'unset']}
				onClick={() => {
					const nextLockedState = !isLocked;
					updateState({ isLocked: nextLockedState });
					syncWithServer({ lockChapterOrganization: nextLockedState });
				}}>
				{!isLocked ? `I’m done. Set my chapter order` : 'Edit chapter organization'}
			</Button>
			<Text fontSize="md" mt="5">
				{!isLocked
					? `By clicking the "I'm done" button, you unlock certain features in the Integrations & Due Dates`
					: `Clicking the Edit button and reordering your chapters will re-lock the Integrations & Due Dates section, until you click "I'm Done" again. Data in that section may be lost.`}
			</Text>
			<Text mt="5">
				{`Chapters will get renumbered before students access your course. If you'd like to rearrange your chapters after you submit your course to Soomo, you may do so by contacting our Customer Success Team before students access.`}
			</Text>
		</>
	);
};

export default ChapterOrganizationForm;
