import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_CHAPTER_VALUES } from 'store/api/constants';
import { Chapter } from 'types';

import { RootState } from '..';

export type DefaultsState = {
	pointsPerChapter: number | string;
	chapters: Chapter[];
};

export const initialState: DefaultsState = {
	pointsPerChapter: DEFAULT_CHAPTER_VALUES.points,
	chapters: []
};

export const defaultsSlice = createSlice({
	name: 'defaults',
	initialState,
	reducers: {
		updateChapterInfo: (state, action: PayloadAction<Chapter[]>) => {
			state.chapters = action.payload;
		}
	}
});

export const { updateChapterInfo } = defaultsSlice.actions;

export const defaultsSliceReducer = defaultsSlice.reducer;

export const selectDefaults = (state: RootState): DefaultsState => state.defaultsSliceReducer;
