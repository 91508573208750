import React from 'react';
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Link,
	Heading,
	VStack,
	useDisclosure,
	Text
} from '@chakra-ui/react';

const BarredSchoolsModal: React.FC = (props) => {
	const { children } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Button onClick={onOpen} px="24">
				{children}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<VStack py="24" px="12" spacing="12">
							<Heading size="2xl">Contact Sales</Heading>

							<Text fontSize="large">
								Thanks for your interest in creating a course! We’d love to work with you. For
								your school, the process starts with a conversation with your school’s account
								manager.
							</Text>

							<a target="_blank" href="mailto:hello@soomolearning.com" rel="noreferrer">
								<Button>Start the conversation</Button>
							</a>

							<Link onClick={onClose}>Close and continue exploring</Link>
						</VStack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default BarredSchoolsModal;
