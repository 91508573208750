let _API_BASE = process.env.REACT_APP_API_BASE;

if (window.Cypress) {
	_API_BASE = 'http://localhost:3006';
}

export const API_BASE = _API_BASE;
export const MARKETING_SITE_URL = process.env.REACT_APP_MARKETING_SITE_URL;

export const DEFAULT_CHAPTER_VALUES = {
	points: 10,
	penaltyPercent: 50,
	penaltyPeriod: 7
};
