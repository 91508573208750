import React from 'react';
import { Radio } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { parseISO, differenceInWeeks } from 'date-fns';

import { ChapterTable } from 'components/controls';
import { RadioGroupControl, SelectControl, TextControl } from 'components/forms';
import { LmsOptions } from 'utils/content';
import { useIntegrations } from 'utils/hooks';
import { CreateCourseFormGlobalState } from 'types';
import { useTrackEvent } from 'hooks';

interface Props {
	isOpen?: boolean;
}

const IntegrationsControl: React.FC<Props> = (props) => {
	const { isOpen } = props;

	const trackEvent = useTrackEvent();
	const {
		register,
		formState: { errors },
		control,
		watch,
		setValue
	} = useFormContext<CreateCourseFormGlobalState>();

	const willThisCourseBeIntegratedWithAnLMS = watch('willThisCourseBeIntegratedWithAnLMS');
	const willYouAssignDueDates = watch('willYouAssignDueDates');
	const willYouAssignAPenaltyPeriod = watch('willYouAssignAPenaltyPeriod');
	const studentAccessDate = watch('studentAccessDate');

	const isDDTLocked =
		willYouAssignDueDates === undefined || willYouAssignAPenaltyPeriod === undefined;

	const isLessThan4WeeksAway = studentAccessDate
		? Math.abs(differenceInWeeks(new Date(), parseISO(studentAccessDate))) < 4
		: false;

	useIntegrations({
		setValue,
		willThisCourseBeIntegratedWithAnLMS,
		willYouAssignDueDates
	});

	return (
		<>
			<Controller
				name="willThisCourseBeIntegratedWithAnLMS"
				control={control}
				render={({ field }) => (
					<RadioGroupControl
						name="willThisCourseBeIntegratedWithAnLMS"
						label="Will this course be integrated with an LMS? (Learning Management System, i.e. Canvas)"
						error={errors.willThisCourseBeIntegratedWithAnLMS?.message}
						helperText={
							isLessThan4WeeksAway
								? 'Please be aware that the LMS integration process may take up to four weeks.'
								: ''
						}
						field={field}
						setValue={(value: string) => {
							setValue('willThisCourseBeIntegratedWithAnLMS', value, { shouldValidate: true });
						}}>
						<Radio colorScheme="blackAlpha" size="lg" value="Yes">
							Yes
						</Radio>
						<Radio colorScheme="blackAlpha" size="lg" value="No">
							No
						</Radio>
					</RadioGroupControl>
				)}
			/>

			{willThisCourseBeIntegratedWithAnLMS === 'Yes' && (
				<>
					<SelectControl
						name="whichLMS"
						label="Which LMS?"
						selectProps={{
							...register('whichLMS')
						}}
						error={errors.whichLMS?.message}>
						<option disabled hidden></option>
						{LmsOptions.map((lms) => (
							<option key={lms} value={lms}>
								{lms}
							</option>
						))}
					</SelectControl>

					<TextControl
						name="pointsPerChapter"
						label="How many points will you assign to each chapter?"
						inputProps={{ ...register('pointsPerChapter'), type: 'number' }}
						error={errors.pointsPerChapter?.message}
					/>

					<Controller
						name="willYouAssignDueDates"
						control={control}
						render={({ field }) => (
							<RadioGroupControl
								name="willYouAssignDueDates"
								label="Will you assign due dates?"
								error={errors.willYouAssignDueDates?.message}
								field={field}
								onChange={(value) => {
									trackEvent('due-dates-assigned', { value: value === 'Yes' ? true : false });
								}}
								setValue={(value: string) => {
									setValue('willYouAssignDueDates', value, { shouldValidate: true });
								}}
								helperText="You can add due dates after the course has been created.">
								<Radio colorScheme="blackAlpha" size="lg" value="Yes">
									Yes
								</Radio>
								<Radio colorScheme="blackAlpha" size="lg" value="No">
									No
								</Radio>
							</RadioGroupControl>
						)}
					/>

					{willYouAssignDueDates === 'Yes' && (
						<Controller
							name="willYouAssignAPenaltyPeriod"
							control={control}
							render={({ field }) => (
								<RadioGroupControl
									name="willYouAssignAPenaltyPeriod"
									label="Will you allow a grace period?"
									error={errors.willYouAssignAPenaltyPeriod?.message}
									field={field}
									setValue={(value: string) => {
										setValue('willYouAssignAPenaltyPeriod', value, { shouldValidate: true });
									}}>
									<Radio colorScheme="blackAlpha" size="lg" value="Yes">
										Yes
									</Radio>
									<Radio colorScheme="blackAlpha" size="lg" value="No">
										No
									</Radio>
								</RadioGroupControl>
							)}
						/>
					)}

					<ChapterTable locked={!isOpen || isDDTLocked} />
				</>
			)}
		</>
	);
};

export default IntegrationsControl;
