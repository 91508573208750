import React from 'react';
import { Heading, Button, Text, VStack, Spacer, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { Card } from 'components';
import { useGetCoursesQuery } from 'store/api';
import { useTrackEvent } from 'hooks';

const UnderReview: React.FC = () => {
	const trackEvent = useTrackEvent();
	const { courseId } = useParams<{ courseId?: string }>();
	const { data: courses } = useGetCoursesQuery(undefined, { refetchOnMountOrArgChange: true });
	const course = courses ? courses.find((c) => c.id === Number(courseId)) : undefined;

	return (
		<>
			<Heading size="2xl" mt={[10, 40]} alignSelf="flex-start">
				Thank you! You’re all set.
			</Heading>
			<Text fontSize="lg" py="6">
				{`Our specialists are excited to customize your course. We’ll follow up by email within two business days.`}
			</Text>

			<Card w="100%" variant="roundedNoPadding">
				<Stack w="100%" p="8" direction={['column', 'row']}>
					<VStack align="left">
						<Text fontSize="xl" fontWeight="bold">
							{course ? course.name : 'Loading...'}
						</Text>
						<Text fontSize="lg" fontWeight="bold">
							{course ? course.number : 'Loading...'}
						</Text>
					</VStack>
					<Spacer />
					<a
						href={`/preview/${course?.textbook_id}`}
						onClick={() => {
							/**
							 * Track when the user returns to the Preview page
							 */
							trackEvent('course-explored', {
								textbook_id: course?.textbook_id,
								course_id: course?.id
							});
						}}>
						<Button size="lg" w={['100%', 'unset']}>
							Explore course
						</Button>
					</a>
				</Stack>
			</Card>
		</>
	);
};

export default UnderReview;
