import React from 'react';
import {
	FormControl,
	FormLabel,
	RadioGroup,
	Stack,
	FormHelperText,
	FormErrorMessage
} from '@chakra-ui/react';
import { ControllerRenderProps } from 'react-hook-form';

interface Props {
	name: string;
	label: string;
	error?: string;
	helperText?: string;
	field: ControllerRenderProps;
	setValue: (value: string) => void;
	onChange?: (value: string) => void;
}

export const RadioGroupControl: React.FC<Props> = (props) => {
	const { name, label, error, helperText, field, setValue, onChange, children } = props;

	return (
		<FormControl label={label} marginBottom="4" isInvalid={!!error} id={`radio-group-${name}`}>
			<FormLabel htmlFor={name}>{label}</FormLabel>
			<RadioGroup
				id={name}
				value={field.value}
				onChange={(value) => {
					if (onChange) onChange(value);
					setValue(value);
				}}
				onBlur={field.onBlur}>
				<Stack spacing="4" direction={['column', 'row']}>
					{children}
				</Stack>
			</RadioGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default RadioGroupControl;
