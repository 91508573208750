import { Chapter, School } from 'types';

export type CreateCourseFormSectionStatus = 'complete' | 'incomplete' | 'locked';

export type CreateCourseFormSection = {
	status: CreateCourseFormSectionStatus;
};

export type CreateCourseFormGlobalState = YourInformationState &
	BasicCourseDetailsState &
	TermInformationState &
	IntegrationsState & {
		note?: string;
	};

export interface CreateModalFormState {
	webtext: number;
	courseName: string;
	courseSectionNumber: string;
	whenWillYouTeachThisCourse: string;
	academicTermId?: number;
	exampleTermName?: string;
	privateTermName?: string;
	termName?: string;
	termStartDate?: string;
	termEndDate?: string;
}

export type YourInformationState = {
	firstName: string;
	lastName: string;
	email: string;
	schoolName: string;
};

export const YourInformationStateKeys: Array<keyof YourInformationState> = [
	'firstName',
	'lastName',
	'email',
	'schoolName'
];

export type BasicCourseDetailsState = {
	courseName: string;
	webtext: number;
	courseSectionNumber: string;
	estimatedNumberOfStudents: string;
};

export const BasicCourseDetailsStateKeys: Array<keyof BasicCourseDetailsState> = [
	'courseName',
	'webtext',
	'courseSectionNumber',
	'estimatedNumberOfStudents'
];

export type TermInformationState = {
	whenWillYouTeachThisCourse: string;
	studentAccessDate?: string;
	academicTermId?: number;
	exampleTermName?: string;
	privateTermName?: string;
	termName?: string;
	termStartDate?: string;
	termEndDate?: string;
};

export const TermInformationStateKeys: Array<keyof TermInformationState> = [
	'whenWillYouTeachThisCourse',
	'studentAccessDate',
	'termName',
	'exampleTermName',
	'privateTermName',
	'termStartDate',
	'termEndDate'
];

export type ChapterOrganizationState = {
	chapters: Chapter[];
	removedChapters: Chapter[];
	isLocked: boolean;
};

export type IntegrationsState = {
	willThisCourseBeIntegratedWithAnLMS: string;
	whichLMS?: string;
	pointsPerChapter?: number;
	willYouAssignDueDates?: string;
	willYouAssignAPenaltyPeriod?: string;
};

export const IntegrationsStateKeys: Array<keyof IntegrationsState> = [
	'willThisCourseBeIntegratedWithAnLMS',
	'whichLMS',
	'pointsPerChapter',
	'willYouAssignDueDates',
	'willYouAssignAPenaltyPeriod'
];

export type FormDataStateElementKey =
	| keyof YourInformationState
	| keyof BasicCourseDetailsState
	| keyof TermInformationState
	| keyof ChapterOrganizationState
	| keyof IntegrationsState;

export type CreateCourseFormState = {
	global: CreateCourseFormGlobalState;
	beingReviewed: boolean;
	verified: boolean;
	school: School;
};

export type CreateCourseFormKey =
	| 'yourInformation'
	| 'basicCourseDetails'
	| 'termInformation'
	| 'integrations';
