import React from 'react';
import { HStack, Td, Tr, Text } from '@chakra-ui/react';

import { Chapter } from 'types';
import { formatDate } from 'utils';
import { ChapterRow, DDTRowFieldConfig } from './types';
import { ChapterTableCell } from '.';

interface Props {
	row: ChapterRow;
	rowIndex: number;
	readOnly?: boolean;
	updateChapterState: (chapterNumber: number, update: Partial<Chapter>) => void;
	isLastRow?: boolean;
	willYouAssignDueDates?: string;
	willYouAssignAPenaltyPeriod?: string;
	willThisCourseBeIntegratedWithAnLMS?: string;
	timeZone: string;
	maxDate?: string;
}

const ChapterTableRow: React.FC<Props> = (props) => {
	const {
		row,
		rowIndex,
		readOnly,
		updateChapterState,
		isLastRow,
		willYouAssignDueDates,
		willYouAssignAPenaltyPeriod,
		willThisCourseBeIntegratedWithAnLMS,
		timeZone,
		maxDate
	} = props;

	const rowFieldConfig: { [K in keyof ChapterRow]: DDTRowFieldConfig } = {
		chapterName: {
			readOnly: true
		},
		chapterNumber: {
			readOnly: true
		},
		points: {
			disabled: !(willThisCourseBeIntegratedWithAnLMS === 'Yes'),
			readOnly: true,
			characterLimit: 4,
			textAlign: 'right'
		},
		dueDate: {
			disabled: !(willYouAssignDueDates === 'Yes'),
			characterLimit: 12,
			textAlign: 'right',
			placeholder: 'MM/DD/YYYY',
			isDate: true,
			maxDate
		},
		dueTime: {
			disabled: !(willYouAssignDueDates === 'Yes'),
			characterLimit: 8,
			textAlign: 'left',
			placeholder: '11:59 PM',
			isTime: true
		},
		penaltyPercent: {
			disabled: !(willYouAssignAPenaltyPeriod === 'Yes'),
			characterLimit: 2,
			textAlign: 'right',
			suffix: '%'
		},
		penaltyPeriod: {
			disabled: !(willYouAssignAPenaltyPeriod === 'Yes'),
			characterLimit: 2,
			textAlign: 'right',
			suffix: 'days'
		}
	};

	return (
		<Tr
			id={`chapter-row-${row.chapterNumber}`}
			key={`row-${rowIndex}`}
			borderTop={rowIndex === 0 && !readOnly ? '2px solid black' : ''}
			borderBottom={isLastRow && !readOnly ? '2px solid black' : ''}>
			{Object.keys(row)
				.filter((k) => (k as keyof ChapterRow) !== 'chapterNumber')
				.map((key, index) => {
					const rowKey = key as keyof ChapterRow;
					const rowValue = row[rowKey];
					const rowConfig = rowFieldConfig[rowKey];

					if (rowConfig.disabled) {
						return;
					}

					return (
						<Td
							id={`chapter-cell-${row.chapterNumber}-${rowKey}`}
							key={`row-${rowIndex}-entry-${index}`}>
							{readOnly || rowConfig.readOnly ? (
								<Text
									fontWeight={readOnly ? 'normal' : 'bold'}
									textAlign={rowConfig.readOnly ? rowConfig.textAlign || 'left' : 'right'}>
									{rowConfig.isDate ? formatDate(String(rowValue)) : rowValue}
									{rowConfig.suffix ? ` ${rowConfig.suffix}` : ''}
								</Text>
							) : (
								<HStack justifyContent="flex-end">
									<ChapterTableCell
										row={row}
										rowKey={rowKey}
										rowValue={rowValue}
										rowConfig={rowConfig}
										timeZone={timeZone}
										updateChapterState={updateChapterState}
									/>
									{rowConfig.suffix && <Text>{rowConfig.suffix}</Text>}
								</HStack>
							)}
						</Td>
					);
				})}
		</Tr>
	);
};

export default ChapterTableRow;
