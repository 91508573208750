import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { CreateCourseFormGlobalState, CreateCourseFormState, School } from 'types';
import type { RootState } from '../';

export const initialState: CreateCourseFormState = {
	global: {
		firstName: '',
		lastName: '',
		email: '',
		schoolName: '',
		courseName: '',
		webtext: 0,
		courseSectionNumber: '',
		estimatedNumberOfStudents: '',
		whenWillYouTeachThisCourse: '',
		studentAccessDate: undefined,
		termName: '',
		exampleTermName: '',
		privateTermName: '',
		termStartDate: undefined,
		termEndDate: undefined,
		willThisCourseBeIntegratedWithAnLMS: '',
		whichLMS: '',
		pointsPerChapter: 10,
		willYouAssignDueDates: '',
		willYouAssignAPenaltyPeriod: '',
		note: ''
	},
	beingReviewed: false,
	verified: false,
	school: {
		id: 0,
		name: '',
		timeZone: '',
		selfServe: false
	}
};

export const createCourseFormSlice = createSlice({
	name: 'createCourseForm',
	initialState,
	reducers: {
		updateBeingReviewed: (state, action: PayloadAction<boolean>) => {
			state.beingReviewed = action.payload;
		},
		updateGlobalState: (state, action: PayloadAction<Partial<CreateCourseFormGlobalState>>) => {
			state.global = { ...state.global, ...action.payload };
		},
		resetGlobalState: (state, action: PayloadAction<Partial<CreateCourseFormGlobalState>>) => {
			state.global = { ...initialState.global, ...action.payload };
		},
		updateVerified: (state, action: PayloadAction<boolean>) => {
			state.verified = action.payload;
		},
		updateSchool: (state, action: PayloadAction<School>) => {
			state.school = action.payload;
		},
		updateSchoolId: (state, action: PayloadAction<number>) => {
			state.school.id = action.payload;
		}
	}
});

export const {
	updateBeingReviewed,
	updateGlobalState,
	resetGlobalState,
	updateVerified,
	updateSchool,
	updateSchoolId
} = createCourseFormSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectCreateCourseForm = (state: RootState): CreateCourseFormState =>
	state.createCourseFormSliceReducer;

export const selectGlobalState = createSelector(selectCreateCourseForm, (state) => state.global);

export const selectIsBeingReviewed = createSelector(
	selectCreateCourseForm,
	(state) => state.beingReviewed
);

export const selectSchool = createSelector(selectCreateCourseForm, (state) => state.school);

export const selectVerified = createSelector(selectCreateCourseForm, (state) => state.verified);

export const selectUserInfo = createSelector(selectGlobalState, (state) => ({
	firstName: state.firstName,
	lastName: state.lastName,
	email: state.email
}));

export const createCourseFormSliceReducer = createCourseFormSlice.reducer;
