import React, { useState } from 'react';
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Link,
	Heading,
	VStack,
	useDisclosure,
	Select,
	FormControl,
	FormLabel
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useGetTextbooksQuery } from 'store/api';
import { useAppSelector } from 'store/hooks';
import { selectGlobalState } from 'store/slices/createCourseFormSlice';
import { useTrackEvent } from 'hooks';

const ChangeWebtextModal: React.FC = () => {
	const history = useHistory();
	const trackEvent = useTrackEvent();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { data: textbooks } = useGetTextbooksQuery();
	const { webtext } = useAppSelector(selectGlobalState);
	const [selectedWebtext, setSelectedWebtext] = useState(webtext);

	const onSwitchWebtext = () => {
		if (selectedWebtext) {
			trackEvent('webtext-preview-launched', {
				from: 'explore-another-webtext',
				textbook_id: selectedWebtext
			});
		}

		onClose();
		history.push(`/preview/${selectedWebtext}`);
	};

	return (
		<>
			<Link
				id="change-webtext"
				onClick={onOpen}
				fontWeight="bold"
				size="sm"
				whiteSpace="nowrap"
				variant="blue"
				mr={[2, 4]}
				fontSize={['sm', 'unset']}
				w={['40%', 'unset']}>
				Explore another webtext
			</Link>
			<Modal isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<VStack p={['4', '32']} py={['8']} spacing={['8', '12']}>
							<Heading size="2xl">Browse another webtext</Heading>

							<FormControl>
								<FormLabel htmlFor="webtext">Select a Webtext</FormLabel>
								<Select
									id="select-webtext"
									name="webtext"
									label="Webtext"
									value={selectedWebtext}
									onChange={(event) => setSelectedWebtext(Number(event.target.value))}>
									{textbooks?.map((tb) => (
										<option key={tb.id} value={tb.id}>
											{tb.externalName}
										</option>
									))}
								</Select>
							</FormControl>

							<Button id="open-webtext" onClick={onSwitchWebtext}>
								Open webtext
							</Button>

							<Link
								size="md"
								color="black"
								href="https://www.soomolearning.com/catalog"
								target="_blank">
								Not sure? Review our catalog
							</Link>
						</VStack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ChangeWebtextModal;
