import React, { useState } from 'react';
import {
	Box,
	Heading,
	Button,
	Flex,
	Text,
	FormControl,
	FormLabel,
	Input,
	Container,
	HStack
} from '@chakra-ui/react';

import { BrandHeader, BrandFooter } from 'components/nav';
import { useLoginMutation } from 'store/api';

const Expired: React.FC = () => {
	const [email, setEmail] = useState('');

	const [login, { isLoading, isSuccess }] = useLoginMutation();

	const onSubmit = (event: React.SyntheticEvent) => {
		event.preventDefault();
		login(email);
	};

	return (
		<Flex height="100%" direction="column" justifyContent="center" alignContent="center">
			<BrandHeader />
			<Box flex="1">
				<Container maxW="container.md">
					<Heading size="3xl" mt="24" mb="10">
						Your session has expired.
					</Heading>

					<Text mb="8" fontSize="xl">
						{isSuccess
							? 'An email containing a magic link has been sent! Check your inbox.'
							: 'Enter your email address below to receive a link to sign back in.'}
					</Text>

					{!isSuccess && (
						<form onSubmit={onSubmit}>
							<FormControl id="email" mb="8" isRequired isDisabled={isLoading}>
								<FormLabel>Email address</FormLabel>
								<Input
									background="white"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormControl>

							<HStack justifyContent="center">
								<Button size="xl" type="submit" isLoading={isLoading}>
									Send Link
								</Button>
							</HStack>
						</form>
					)}
				</Container>
			</Box>
			<BrandFooter />
		</Flex>
	);
};

export default Expired;
