import React from 'react';
import {
	FormControl,
	FormLabel,
	SelectProps,
	Select,
	FormHelperText,
	FormControlProps,
	FormErrorMessage
} from '@chakra-ui/react';

interface Props {
	name: string;
	label: string;
	error?: string;
	helperText?: string;
	formControlProps?: FormControlProps;
	selectProps?: SelectProps;
}

const SelectControl: React.FC<Props> = (props) => {
	const { name, label, helperText, selectProps, formControlProps, error, children } = props;

	return (
		<FormControl marginBottom="4" {...formControlProps} isInvalid={!!error}>
			<FormLabel htmlFor={name}>{label}</FormLabel>
			<Select {...selectProps}>{children}</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default SelectControl;
