import React from 'react';
import { VStack, Heading, Button, Flex } from '@chakra-ui/react';
import { BrandHeader, BrandFooter } from 'components/nav';
import { MARKETING_SITE_URL } from 'store/api';

const Error: React.FC = () => {
	return (
		<Flex height="100%" direction="column">
			<BrandHeader />
			<VStack flex="1">
				<Heading size="3xl" mb="12" mt="24">
					Something went wrong.
				</Heading>
				<a href={MARKETING_SITE_URL}>
					<Button size="xl" px="6">
						Return to the homepage
					</Button>
				</a>
			</VStack>
			<BrandFooter />
		</Flex>
	);
};

export default Error;
