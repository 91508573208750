import React, { useState } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';

interface Props {
	src: string;
}

const WebtextIframe: React.FC<Props> = (props) => {
	const { src } = props;
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
	}, [src]);

	return (
		<Flex w="100%" h="100%" justifyContent="center" alignItems="center">
			{loading && <Spinner size="xl" />}
			<iframe
				src={src}
				width={loading ? '0' : '100%'}
				height={loading ? '0' : '100%'}
				onLoad={() => setLoading(false)}
				frameBorder="0"
				allow="camera *; microphone *"
			/>
		</Flex>
	);
};

export default WebtextIframe;
