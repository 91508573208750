import { useEffect, useMemo } from 'react';

import { useAppSelector, useAppDispatch } from 'store';
import { useGetTextbooksChaptersQuery, useGetTextbooksQuery } from 'store/api';
import { updateChapterOrganizationState } from 'store/slices/chapterOrganizationSlice';
import { selectGlobalState, selectSchool } from 'store/slices/createCourseFormSlice';
import { updateChapterInfo } from 'store/slices/defaultsSlice';
import { ChapterConfig, SelfServeConfig } from 'types';
import { prepareChapters } from 'utils';

export function useSelfServeConfig(): SelfServeConfig {
	const { webtext: textbookId, termStartDate, termEndDate } = useAppSelector(selectGlobalState);
	const { data: textbooks } = useGetTextbooksQuery();
	const school = useAppSelector(selectSchool);
	const dispatch = useAppDispatch();

	/**
	 * We need to make sure textbook chapters are always requested even if the course is not
	 * customizable. Without this call here, the only place we request chapters is inside of
	 * `<ChapterOrganization>`. Which is not rendered when a textbook is not customizable.
	 */
	const { data: textbookChapters } = useGetTextbooksChaptersQuery(textbookId);

	/**
	 * Check `SelfServeConfig` for `customizable` attribute.
	 */
	const isCustomizable = useMemo(() => {
		const textbook = textbooks?.find((t) => t.id === Number(textbookId));
		if (textbook && textbook.selfServeConfig) {
			return textbook.selfServeConfig.customizable;
		}
		return true;
	}, [textbookId, textbooks]);

	/**
	 * Surface the chapter configuration from the server or generate a default if the textbook
	 * is fully customizable.
	 */
	const chapterConfig = useMemo<{
		anchorFirstChapter?: boolean;
		chapterConfig?: Record<string, ChapterConfig>;
		chapterExplanation?: string;
	}>(() => {
		if (!textbooks || !textbookChapters) return {};

		const textbook = textbooks?.find((t) => t.id === Number(textbookId));
		const chapterConfigurations = textbook?.selfServeConfig?.chapter_configurations;

		/**
		 * If we got chapter configuration from the server, return that value.
		 * 
		 * UPDATE THIS COMMENT
		 */
		if (chapterConfigurations && Object.keys(chapterConfigurations).length > 0) {
			return {
				chapterExplanation: textbook?.selfServeConfig?.chapter_explanation,
				...chapterConfigurations
			};
		}

		/**
		 * Return a fully customizable chapter customization when the selected textbook does
		 * not have configuration attached to it.
		 */
		return {
			anchorFirstChapter: false,
			chapterConfig: textbookChapters?.reduce<Record<string, ChapterConfig>>(
				(acc, chapter) => ({
					...acc,
					[chapter.chapterFamilyId]: { removable: true, draggable: true }
				}),
				{}
			),
			chapterExplanation: textbook?.selfServeConfig?.chapter_explanation
		};
	}, [textbookChapters, textbookId, textbooks]);

	useEffect(() => {
		/**
		 * If the textbook does not allow customization, we need to "prepareChapters" here.  If the
		 * course is customizable then this is handled inside of `<ChapterOrganizationForm>`
		 *
		 * If the course is customizable we need to make sure the chapter state is cleared so it
		 * can be handled inside of `<ChapterOrganizationState>`.  This is to handle the case where
		 * the user enters the review form with a non-customizable course and changes to a
		 * customizable one.
		 */
		if (!isCustomizable && textbookChapters) {
			const preparedChapterState = prepareChapters(
				textbookChapters,
				school.timeZone,
				termStartDate,
				termEndDate
			);
			dispatch(updateChapterOrganizationState(preparedChapterState));
			dispatch(updateChapterInfo(preparedChapterState.chapters));
		}
	}, [dispatch, isCustomizable, school.timeZone, termEndDate, termStartDate, textbookChapters]);

	return {
		isCustomizable,
		anchorFirstChapter: chapterConfig?.anchorFirstChapter || false,
		chapterConfig: chapterConfig?.chapterConfig || {},
		chapterExplanation: chapterConfig?.chapterExplanation
	};
}
