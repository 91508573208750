import React from 'react';
import { Box, useStyleConfig, BoxProps } from '@chakra-ui/react';

interface Props {
	variant?: 'rounded' | 'roundedNoPadding';
}

const Card: React.FC<Props & BoxProps> = (props) => {
	const { variant } = props;
	const styles = useStyleConfig('Card', { variant });

	return (
		<Box __css={styles} {...props}>
			{props.children}
		</Box>
	);
};

export default Card;
