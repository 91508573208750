import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useDebounce } from '@soomo/lib/hooks';
import { useAppDispatch } from 'store';
import { useGetSchoolsQuery } from 'store/api';
import { TextControl, TypeaheadControl } from 'components/forms';
import { CreateCourseFormGlobalState } from 'types';
import { updateSchool } from 'store/slices/createCourseFormSlice';

const YourInformationForm: React.FC = () => {
	const dispatch = useAppDispatch();

	const {
		register,
		control,
		formState: { errors },
		setValue,
		watch
	} = useFormContext<CreateCourseFormGlobalState>();

	const schoolName = watch('schoolName');

	const debouncedSchoolName = useDebounce(schoolName, 500);
	const { data } = useGetSchoolsQuery(debouncedSchoolName);
	const schools = data ? data?.map((s) => s.name) : [];
	const school = data?.find((s) => s.name === schoolName);

	useEffect(() => {
		if (school) {
			dispatch(updateSchool(school));
		} else {
			dispatch(
				updateSchool({
					id: 0,
					name: debouncedSchoolName,
					selfServe: true,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				})
			);
		}
	}, [dispatch, school, debouncedSchoolName]);

	return (
		<>
			<TextControl
				name="firstName"
				label="First Name"
				error={errors.firstName?.message}
				inputProps={{ ...register('firstName'), placeholder: 'First' }}
			/>

			<TextControl
				name="lastName"
				label="Last Name"
				error={errors.lastName?.message}
				inputProps={{ ...register('lastName'), placeholder: 'Last' }}
			/>

			<TextControl
				name="email"
				label="Email"
				error={errors.email?.message}
				inputProps={{
					...register('email'),
					placeholder: 'someone@somewhere.com',
					type: 'email',
					isReadOnly: true
				}}
			/>

			<Controller
				name="schoolName"
				control={control}
				render={({ field }) => (
					<TypeaheadControl
						name="schoolName"
						label="School Name"
						items={Array.from(new Set(schools))}
						school={school}
						field={field}
						setValue={(value: string) => {
							setValue('schoolName', value);
						}}
					/>
				)}
			/>
		</>
	);
};

export default YourInformationForm;
