import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
	link: string;
}

const ExternalRedirect: React.FC<Props> = (props: Props) => {
	const { link, ...routeProps } = props;
	return (
		<Route
			{...routeProps}
			render={() => {
				window.location.replace(link);
				return null;
			}}
		/>
	);
};

export default ExternalRedirect;
