export const Button = {
	// The styles all button have in common
	baseStyle: {
		fontWeight: 'bold',
		borderRadius: 'lg'
	},
	sizes: {
		lg: {
			fontSize: 'lg',
			px: 12,
			py: 6
		},
		xl: {
			fontSize: 'xl',
			px: 20,
			py: 5
		}
	},
	// Two variants: outline and solid
	variants: {
		solid: {
			bg: 'brandBlue.500',
			color: 'white',
			_hover: {
				bg: 'brandBlue.700',
				_disabled: {
					bgColor: 'brandBlue.100'
				}
			},
			_active: {
				bg: 'background.700'
			},
			_disabled: {
				bg: 'brandBlue.200',
				opacity: 0.9
			}
		},
		solidWebtext: {
			bg: 'white',
			color: 'brand.400'
		}
	},
	// The default size and variant values
	defaultProps: {
		size: 'lg',
		variant: 'solid'
	}
};
