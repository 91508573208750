import React from 'react';
import { Thead, Tr, Th, Text, VStack, chakra } from '@chakra-ui/react';

interface Props {
	readOnly?: boolean;
	willYouAssignDueDates?: string;
	willYouAssignAPenaltyPeriod?: string;
	willThisCourseBeIntegratedWithAnLMS?: string;
}

const ChapterTableHead: React.FC<Props> = (props) => {
	const {
		readOnly,
		willYouAssignDueDates,
		willYouAssignAPenaltyPeriod,
		willThisCourseBeIntegratedWithAnLMS
	} = props;

	const verticalAlign = readOnly ? 'top' : 'bottom';

	return (
		<Thead>
			<Tr>
				<Th
					color="black"
					verticalAlign={verticalAlign}
					fontSize={readOnly ? 'md' : 'xs'}
					pl="0"
					fontFamily="body">
					{readOnly ? 'Chapter Name & Number' : 'Chapter'}
				</Th>
				{willThisCourseBeIntegratedWithAnLMS === 'Yes' && (
					<Th
						color="black"
						verticalAlign={verticalAlign}
						textAlign="right"
						fontFamily="body"
						px="0">
						Points
					</Th>
				)}
				{willYouAssignDueDates === 'Yes' && (
					<>
						<Th color="black" verticalAlign={verticalAlign} textAlign="right">
							Due Date
						</Th>
						<Th color="black" verticalAlign={verticalAlign} textAlign="right">
							Due Time *
						</Th>
					</>
				)}
				{willYouAssignAPenaltyPeriod === 'Yes' && (
					<>
						<Th color="black" verticalAlign={verticalAlign} textAlign="right" px="1">
							% Deduction
						</Th>
						<Th color="black" verticalAlign={verticalAlign} textAlign="right" px="0">
							<VStack w="100%" align="flex-end">
								<Text>Grace Period</Text>
								<chakra.span fontSize="x-small" fontWeight="medium" style={{ marginTop: 0 }}>
									# days after due
								</chakra.span>
							</VStack>
						</Th>
					</>
				)}
			</Tr>
		</Thead>
	);
};

export default ChapterTableHead;
