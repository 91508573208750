import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { ChapterOrganizationState } from 'types';
import { RootState } from '../';

export const initialState: ChapterOrganizationState = {
	chapters: [],
	removedChapters: [],
	isLocked: false
};

export const chapterOrganizationSlice = createSlice({
	name: 'chapterOrganization',
	initialState,
	reducers: {
		updateChapterOrganizationState: (state, action: PayloadAction<ChapterOrganizationState>) => {
			state.chapters = action.payload.chapters;
			state.removedChapters = action.payload.removedChapters;
			state.isLocked = action.payload.isLocked;
		},
		resetChapterOrganization: (state) => {
			state.chapters = initialState.chapters;
			state.removedChapters = initialState.removedChapters;
			state.isLocked = initialState.isLocked;
		}
	}
});

export const { updateChapterOrganizationState, resetChapterOrganization } =
	chapterOrganizationSlice.actions;

export const chapterOrganizationSliceReducer = chapterOrganizationSlice.reducer;

export const selectChapterOrganization = (state: RootState): ChapterOrganizationState =>
	state.chapterOrganizationSliceReducer;

export const selectChapters = createSelector(selectChapterOrganization, (state) => state.chapters);
