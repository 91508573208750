import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { RootState } from '../';

type ContentState = {
	accessToken: string;
	intercomUserHash: string;
	courseId: number;
	userId: number;
};

export const initialState: ContentState = {
	accessToken: '',
	intercomUserHash: '',
	courseId: 0,
	userId: 0
};

export const contentSlice = createSlice({
	name: 'content',
	initialState,
	reducers: {
		updateAccessToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
		updateIntercomUserHash: (state, action: PayloadAction<string>) => {
			state.intercomUserHash = action.payload;
		},
		updateCourseId: (state, action: PayloadAction<number>) => {
			state.courseId = action.payload;
		},
		updateUserId: (state, action: PayloadAction<number>) => {
			state.userId = action.payload;
		}
	}
});

export const { updateAccessToken, updateIntercomUserHash, updateCourseId, updateUserId } =
	contentSlice.actions;

export const contentSliceReducer = contentSlice.reducer;

export const selectContent = (state: RootState): ContentState => state.contentSliceReducer;

export const selectAccessToken = createSelector(selectContent, (state) => state.accessToken);

export const selectIntercomUserHash = createSelector(
	selectContent,
	(state) => state.intercomUserHash
);

export const selectCourseId = createSelector(selectContent, (state) => state.courseId);

export const selectUserId = createSelector(selectContent, (state) => state.userId);
