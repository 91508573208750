import { useEffect, useRef } from 'react';

import { useGetTextbooksQuery } from 'store/api';
import { useAppSelector } from 'store/hooks';
import { selectIntercomUserHash, selectUserId } from 'store/slices/contentSlice';
import { selectUserInfo } from 'store/slices/createCourseFormSlice';

interface BootIntercomConfig {
	firstName: string;
	lastName: string;
	email: string;
	userId: number;
	userHash: string;
}

export type TrackableEventKey =
	| 'webtext-preview-launched'
	| 'create-modal-started'
	| 'create-modal-1-of-3-completed'
	| 'create-modal-2-of-3-completed'
	| 'create-modal-3-of-3-completed'
	| 'review-form-started'
	| 'review-finished'
	| 'review-form-updated'
	| 'due-dates-assigned'
	| 'review-form-confirmation-displayed'
	| 'review-form-submitted'
	| 'course-explored'
	| 'webtext-chapters-viewed'
	| 'page-from-enter-button-visited'
	| 'page-visited';

export interface TrackableEventMetadata {
	from?: string;
	course_id?: number;
	textbook_id?: number;
	valid?: boolean;
	value?: boolean | string | number;
}

/**
 * Wrap Intercom booting for easy calling
 */
export function bootIntercom(config: BootIntercomConfig): void {
	window.Intercom('boot', {
		app_id: process.env.REACT_APP_INTERCOM_TOKEN,
		name: `${config.firstName} ${config.lastName}`,
		email: config.email,
		user_id: `${config.userId}`,
		user_hash: config.userHash
	});
}

/**
 * Returns a function that can be used to track Intercom events.
 */
export function useTrackEvent(): (
	eventKey: TrackableEventKey,
	metadata?: TrackableEventMetadata
) => void {
	const { data: textbooks } = useGetTextbooksQuery();

	return (eventKey: TrackableEventKey, metadata?: TrackableEventMetadata) => {
		const buildMetadata = (metadata?: TrackableEventMetadata) => {
			/**
			 * If the metadata contains a `textbook_id` we should lookup this textbook and include
			 * more information about it in the metadata.
			 */
			if (metadata?.textbook_id) {
				const selectedTextbook = textbooks?.find((t) => t.id === metadata.textbook_id);
				if (selectedTextbook) {
					return {
						...metadata,
						textbook_internal_name: selectedTextbook.internalName,
						textbook_external_name: selectedTextbook.externalName
					};
				}
			}

			return { ...metadata };
		};

		window.Intercom('trackEvent', `selfserve-${eventKey}`, buildMetadata(metadata));
	};
}

/**
 * When used this hook will boot Intercom with values from the store
 */
export function useBootIntercom(): void {
	const hasBooted = useRef(false);
	const userId = useAppSelector(selectUserId);
	const userInfo = useAppSelector(selectUserInfo);
	const intercomUserHash = useAppSelector(selectIntercomUserHash);

	useEffect(() => {
		if (userId && intercomUserHash && !hasBooted.current) {
			bootIntercom({
				userId,
				userHash: intercomUserHash,
				...userInfo
			});
			hasBooted.current = true;
		}
	}, [intercomUserHash, userId, userInfo]);
}

export function useTrackExternalWebtext(props: { textbookId: number }): void {
	const { textbookId } = props;
	const trackEvent = useTrackEvent();

	/**
	 * Listen for events from iframes and windows that we've spawned
	 */
	window.onmessage = function (e) {
		/**
		 * Received an event from the iframe / new window, check if its what we are looking for
		 */
		if (e.data === 'page-from-enter-button-visited') {
			trackEvent('page-from-enter-button-visited', {
				textbook_id: textbookId
			});
		} else if (e.data === 'page-visited') {
			trackEvent('page-visited');
		}
	};
}
