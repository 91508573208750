import React, { useState, useEffect } from 'react';
import {
	Box,
	Heading,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	Button,
	Text,
	VStack,
	Link,
	HStack,
	Stack,
	FormControl,
	FormLabel,
	Spacer,
	Spinner
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { MdArrowBack, MdClose } from 'react-icons/md';
import { useForm, Controller, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { SelectControl, TextControl, DatePickerControl } from 'components/forms';
import { BarredSchoolsModal } from 'components/modals';
import { formatDate, getYearFromDate } from 'utils';
import { ExampleTerms } from 'utils/content';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store/hooks';
import {
	useGetAcademicTermsQuery,
	useGetSchoolsQuery,
	useGetTextbooksQuery,
	useUpdateCourseCreationMutation
} from 'store/api';
import {
	selectGlobalState,
	selectSchool,
	resetGlobalState
} from 'store/slices/createCourseFormSlice';
import { CourseCreationRequest, CreateModalFormState } from 'types';
import { updateCourseId } from 'store/slices/contentSlice';
import { useTermInformation } from 'utils/termInformationHooks';
import { CreateModalFormSchema } from 'types/schema';
import { useTrackEvent } from 'hooks';
import { resetChapterOrganization } from 'store/slices/chapterOrganizationSlice';

const STEP_COUNT = 3;

const STEP_COPY = [
	`Choose your webtext`,
	`Tell us some course details`,
	`When will you teach this course?`
];

const REQUIRED_FIELD_MESSAGE = 'Please complete this field to proceed';

const CreateModalStep: React.FC<{
	stepNumber: number;
	formProps: UseFormReturn<CreateModalFormState>;
	onSubmit: () => void;
}> = (props) => {
	const { stepNumber, formProps, onSubmit } = props;

	// react-hook-form
	const {
		control,
		register,
		setValue,
		watch,
		formState: { errors },
		trigger,
		setError,
		clearErrors
	} = formProps;

	const watchWhenWillYouTeachThisCourse = watch('whenWillYouTeachThisCourse');
	const watchPrivateTermName = watch('privateTermName');
	const watchExampleTermName = watch('exampleTermName');
	const watchTermName = watch('termName');
	const watchTermStartDate = watch('termStartDate');
	const watchTermEndDate = watch('termEndDate');

	const schoolId = useAppSelector(selectSchool).id;
	const { data: academicTerms } = useGetAcademicTermsQuery(schoolId);
	const { data: textbooks } = useGetTextbooksQuery();

	const termNames = [
		...(academicTerms ? academicTerms.map((at) => at.label) : []),
		...(watchWhenWillYouTeachThisCourse === 'I know the exact term' ? ['I don’t see my term'] : [])
	];

	const hasAcademicTerms = academicTerms && academicTerms?.length > 0;

	useTermInformation({
		setValue,
		termName: watchTermName,
		whenWillYouTeachThisCourse: watchWhenWillYouTeachThisCourse,
		academicTerms: academicTerms
	});

	switch (stepNumber) {
		case 0: {
			return (
				<>
					<SelectControl
						name="webtext"
						label="Webtext"
						formControlProps={{ isRequired: true }}
						selectProps={{ ...register('webtext') }}>
						{textbooks?.map((t) => (
							<option key={t.id} value={t.id}>
								{t.externalName}
							</option>
						))}
					</SelectControl>
				</>
			);
		}

		case 1: {
			return (
				<>
					<TextControl
						name="courseName"
						label="Course Name"
						error={errors.courseName?.message}
						inputProps={{ ...register('courseName'), placeholder: 'Something 101' }}
						formControlProps={{ isRequired: true }}
					/>

					<TextControl
						name="courseSectionNumber"
						label="Course Number (and section number, if you have it)"
						error={errors.courseSectionNumber?.message}
						inputProps={{ ...register('courseSectionNumber'), placeholder: 'SOME-101' }}
						formControlProps={{ isRequired: true }}
					/>
				</>
			);
		}

		case 2: {
			return (
				<>
					<HStack>
						{!watchWhenWillYouTeachThisCourse && (
							<Stack direction={['column', 'row']} w="100%" justify="center" alignItems="center">
								<Button
									w={['100%', 'unset']}
									flex={['unset', '2']}
									mb="0"
									onClick={() => {
										setValue('whenWillYouTeachThisCourse', 'I know the exact term');
									}}>
									I know the exact term
								</Button>
								<Button
									w={['100%', 'unset']}
									flex={['unset', '2']}
									onClick={() => {
										setValue('whenWillYouTeachThisCourse', `I’m not sure`);
									}}>
									{`I’m not sure`}
								</Button>
							</Stack>
						)}
						{watchWhenWillYouTeachThisCourse && (
							<VStack w="100%">
								{watchWhenWillYouTeachThisCourse === 'I know the exact term' && (
									<>
										{hasAcademicTerms && (
											<>
												<SelectControl
													name="termName"
													label="Term Options"
													formControlProps={{ isRequired: true }}
													selectProps={{
														...register('termName'),
														placeholder: 'Select your term'
													}}
													error={errors.termName?.message}>
													<option disabled hidden></option>
													{termNames.map((o) => (
														<option key={o} value={o}>
															{o}
														</option>
													))}
												</SelectControl>

												{watchTermName && watchTermName !== 'I don’t see my term' && (
													<Box my="10">
														<HStack justifyContent="start">
															<FormControl w="initial" mr="6">
																<FormLabel>Term Start Date</FormLabel>
																<Text fontSize="lg">
																	{watchTermStartDate
																		? formatDate(String(watchTermStartDate))
																		: '{issue}'}
																</Text>
															</FormControl>
															<FormControl w="initial" mr="6">
																<FormLabel>Term End Date</FormLabel>
																<Text fontSize="lg">
																	{watchTermEndDate
																		? formatDate(String(watchTermEndDate))
																		: '{issue}'}
																</Text>
															</FormControl>
														</HStack>
													</Box>
												)}
											</>
										)}

										{(!hasAcademicTerms || watchTermName === 'I don’t see my term') && (
											<Box w="100%">
												<TextControl
													name="privateTermName"
													label="Term name"
													inputProps={{ ...register('privateTermName') }}
													formControlProps={{ isRequired: true }}
													error={errors.privateTermName?.message}
												/>

												<HStack my="6" w="100%">
													<Controller
														name="termStartDate"
														control={control}
														render={({ field }) => (
															<DatePickerControl
																name="termStartDate"
																label="Term start date"
																field={field}
																setValue={(value: string) => {
																	setValue('termStartDate', value);
																	trigger('termStartDate');
																}}
																formControlProps={{
																	w: 'initial',
																	mr: '6',
																	flex: '1',
																	mb: '0',
																	isRequired: true
																}}
																error={errors.termStartDate?.message}
															/>
														)}
													/>

													<Text
														alignSelf="center"
														textAlign="center"
														fontSize="lg"
														pt="7"
														ml="0"
														pr="4"
														marginInlineStart="0 !important">
														-
													</Text>

													<Controller
														name="termEndDate"
														control={control}
														render={({ field }) => (
															<DatePickerControl
																name="termEndDate"
																label="Term end date"
																field={field}
																setValue={(value: string) => {
																	setValue('termEndDate', value);
																	trigger('termEndDate');
																}}
																formControlProps={{
																	w: 'initial',
																	mr: '6',
																	flex: '1',
																	isRequired: true
																}}
																error={errors.termEndDate?.message}
															/>
														)}
													/>
												</HStack>
											</Box>
										)}
									</>
								)}

								{watchWhenWillYouTeachThisCourse === `I’m not sure` && (
									<>
										<SelectControl
											name="exampleTermName"
											label="Select the term that best applies"
											formControlProps={{ isRequired: true }}
											selectProps={{
												...register('exampleTermName'),
												placeholder: 'Select your term'
											}}
											error={errors.exampleTermName?.message}>
											<option disabled hidden></option>
											{ExampleTerms.map((o) => (
												<option key={o} value={o}>
													{o}
												</option>
											))}
										</SelectControl>

										<Controller
											name="termStartDate"
											control={control}
											render={({ field }) => (
												<DatePickerControl
													name="termStartDate"
													label="Approximate term start date"
													field={field}
													setValue={(value: string) => {
														setValue('termStartDate', value);
														trigger('termStartDate');
													}}
													formControlProps={{ isRequired: true }}
													error={errors.termStartDate?.message}
												/>
											)}
										/>
									</>
								)}

								<Button
									w={['100%', 'unset']}
									style={{ marginTop: 32 }}
									onClick={() => {
										clearErrors([
											'privateTermName',
											'exampleTermName',
											'termName',
											'termStartDate',
											'termEndDate'
										]);

										if (watchWhenWillYouTeachThisCourse === 'I know the exact term') {
											/**
											 * Has academic terms and creating private term or they
											 * are selecting an academic term
											 */
											if (
												(hasAcademicTerms && watchTermName === `I don’t see my term`) ||
												!hasAcademicTerms
											) {
												if (!watchPrivateTermName) {
													setError('privateTermName', { message: REQUIRED_FIELD_MESSAGE });
												}

												if (!watchTermStartDate) {
													setError('termStartDate', { message: REQUIRED_FIELD_MESSAGE });
												}

												if (!watchTermEndDate) {
													setError('termEndDate', { message: REQUIRED_FIELD_MESSAGE });
												}
											} else {
												if (!watchTermName) {
													setError('termName', { message: REQUIRED_FIELD_MESSAGE });
												}
											}
										} else if (watchWhenWillYouTeachThisCourse === `I’m not sure`) {
											if (!watchExampleTermName) {
												setError('exampleTermName', { message: REQUIRED_FIELD_MESSAGE });
											}

											if (!watchTermStartDate) {
												setError('termStartDate', { message: REQUIRED_FIELD_MESSAGE });
											}
										}

										if (Object.keys(errors).length === 0) {
											onSubmit();
										}
									}}>
									Continue
								</Button>
							</VStack>
						)}
					</HStack>
				</>
			);
		}

		default:
			return null;
	}
};

interface Props {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}

const Create: React.FC<Props> = (props) => {
	const { isOpen, onOpen, onClose } = props;

	const history = useHistory();
	const trackEvent = useTrackEvent();
	const dispatch = useAppDispatch();
	const $globalState = useAppSelector(selectGlobalState);
	const { webtext } = $globalState;
	const [step, setStep] = useState(0);

	const schoolId = useAppSelector(selectSchool).id;
	const { data: academicTerms } = useGetAcademicTermsQuery(schoolId);
	const [updateCourseCreation, { isLoading: isUpdating }] = useUpdateCourseCreationMutation();

	const { schoolName } = useAppSelector(selectGlobalState);
	const { data: schools } = useGetSchoolsQuery(schoolName);
	const currentSchool = schools?.find((s) => s.name === schoolName);

	// react-hook-form
	const formProps = useForm<CreateModalFormState>({
		defaultValues: {
			webtext: webtext,
			courseName: '',
			courseSectionNumber: '',
			whenWillYouTeachThisCourse: '',
			privateTermName: undefined,
			exampleTermName: undefined,
			academicTermId: undefined,
			termName: undefined,
			termStartDate: '',
			termEndDate: ''
		},
		mode: 'onChange',
		resolver: yupResolver(CreateModalFormSchema)
	});

	const { handleSubmit, watch, trigger, setValue } = formProps;

	const watchWhenWillYouTeachThisCourse = watch('whenWillYouTeachThisCourse');

	const goToNextStep = () => {
		const goToNext = () => {
			if (step + 1 <= STEP_COUNT) {
				/**
				 * Track when the user completes step 1 and 2 of the <Create> modal
				 */
				if (step === 0) {
					trackEvent('create-modal-1-of-3-completed');
				} else if (step === 1) {
					trackEvent('create-modal-2-of-3-completed');
				}

				setStep(step + 1);
			}
		};

		if (step === 0) {
			trigger(['webtext']).then((result) => {
				if (result) {
					goToNext();
				}
			});
		} else if (step === 1) {
			trigger(['courseName', 'courseSectionNumber']).then((result) => {
				if (result) {
					goToNext();
				}
			});
		}
	};

	const goToPrevStep = (formProps: UseFormReturn<CreateModalFormState>) => {
		if (step === 2 && watchWhenWillYouTeachThisCourse) {
			formProps.setValue('whenWillYouTeachThisCourse', '');
			formProps.setValue('privateTermName', '');
			formProps.setValue('exampleTermName', '');
			formProps.setValue('termName', '');
			formProps.setValue('termStartDate', '');
			formProps.setValue('termEndDate', '');
		} else if (step - 1 >= 0) {
			setStep(step - 1);
		}
	};

	/**
	 * Update webtext when it changes elsewhere
	 */
	useEffect(() => {
		setValue('webtext', webtext);
	}, [setValue, webtext]);

	/**
	 * If currentSchool is in our system and that school is barred from using self-serve show
	 * the barred schools modal.
	 */
	if (currentSchool && !currentSchool?.selfServe) {
		return <BarredSchoolsModal>Create a new course</BarredSchoolsModal>;
	}

	const submitForm = (values: CreateModalFormState) => {
		const academicTerm = academicTerms?.find((t) => t.label === values.termName);

		/**
		 * Prepare the term name if a custom academic term is being created.
		 *
		 * exampleTermName - Selected from dropdown when the user selects "I’m not sure"
		 * privateTermName - Provided by user via text input when they know their exact term
		 */
		const termName =
			values.whenWillYouTeachThisCourse === `I’m not sure`
				? `${values.exampleTermName} ${getYearFromDate(String(values.termStartDate))}`
				: values.privateTermName;

		const payload: CourseCreationRequest = {
			course: {
				textbook_id: values.webtext,
				name: values.courseName,
				number: values.courseSectionNumber,
				...(academicTerm && { academic_term_id: academicTerm.id })
			},
			...(!academicTerm && {
				academic_term: {
					name: termName || null,
					start_date: values.termStartDate || null,
					end_date: values.termEndDate || null
				}
			}),
			school: {
				id: schoolId,
				name: schoolName
			}
		};

		updateCourseCreation(payload).then((response) => {
			const courseId = (response as { data: number }).data;

			if (courseId) {
				dispatch(updateCourseId(courseId));
			}

			/**
			 * After course creation, reset global state to get rid of state leftover from the last
			 * creation process
			 */
			dispatch(
				resetGlobalState({
					firstName: $globalState.firstName,
					lastName: $globalState.lastName,
					email: $globalState.email,
					schoolName: $globalState.schoolName,
					...values
				})
			);

			dispatch(resetChapterOrganization());

			/**
			 * Track when the user has complete last step of the <Create> modal
			 */
			trackEvent('create-modal-3-of-3-completed');

			onClose();
			history.push('/review');
		});
	};

	return (
		<Box as="form" w={['40%', 'unset']}>
			<Button onClick={onOpen} date-testid="create-a-new-course" w={['100%', 'unset']}>
				{props.children}
			</Button>

			<Modal
				id="create-modal"
				isOpen={isOpen}
				onClose={() => {
					setStep(0);
					onClose();
				}}
				size="4xl">
				<ModalOverlay />
				<ModalContent mt={["0", '16']}>
					<ModalHeader>
						<HStack>
							{step > 0 && (
								<Button
									onClick={() => goToPrevStep(formProps)}
									leftIcon={<MdArrowBack />}
									variant="ghost"
									px="2">
									Back
								</Button>
							)}
							<Spacer />
							<Button onClick={onClose} rightIcon={<MdClose />} variant="ghost" px="2">
								Exit
							</Button>
						</HStack>
					</ModalHeader>
					<ModalBody>
						{isUpdating ? (
							<VStack justifyContent="center" h="container.sm">
								<Spinner size="xl" />
							</VStack>
						) : (
							<VStack p={['4', '8']} pb="20" h={['unset', 'container.md']}>
								<VStack mb="8" spacing="4">
									<Text fontSize="xl">Create a new course</Text>
									<Heading size="2xl" textAlign={['center', 'unset']}>
										{STEP_COPY[step]}
									</Heading>
									<Text>Step {step + 1} of 3</Text>
								</VStack>

								<VStack w="100%" spacing={[4]}>
									<Box w={['100%', '75%']}>
										<CreateModalStep
											stepNumber={step}
											formProps={formProps}
											onSubmit={handleSubmit(submitForm)}
										/>
									</Box>

									{step !== 2 && (
										<Button onClick={goToNextStep} w={['100%', 'unset']}>
											Continue
										</Button>
									)}

									{(step !== 2 || watchWhenWillYouTeachThisCourse !== '') && (
										<Text>* = required</Text>
									)}

									{step === 0 && (
										<Link
											size="md"
											color="black"
											href="https://www.soomolearning.com/catalog"
											target="_blank">
											Not sure? Review our catalog
										</Link>
									)}
								</VStack>
							</VStack>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default Create;
