import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextControl, SelectControl } from 'components/forms';
import { useGetTextbooksQuery } from 'store/api';
import { CreateCourseFormGlobalState } from 'types';

const BasicCourseDetailsForm: React.FC = () => {
	const {
		register,
		formState: { errors }
	} = useFormContext<CreateCourseFormGlobalState>();

	const { data: textbooks } = useGetTextbooksQuery();

	return (
		<>
			<TextControl
				name="courseName"
				label="Course Name"
				error={errors.courseName?.message}
				inputProps={{ ...register('courseName'), placeholder: 'Something 101' }}
			/>

			<SelectControl
				name="webtext"
				label="Webtext"
				selectProps={{
					...register('webtext')
				}}
				error={errors.webtext?.message}>
				{textbooks?.map((t) => (
					<option key={t.id} value={t.id}>
						{t.externalName}
					</option>
				))}
			</SelectControl>

			<TextControl
				name="courseSectionNumber"
				label="Course and/or section number"
				error={errors.courseSectionNumber?.message}
				inputProps={{ ...register('courseSectionNumber'), placeholder: 'SOME-101' }}
			/>

			<TextControl
				name="estimatedNumberOfStudents"
				label="Estimated number of students in this section"
				error={errors.estimatedNumberOfStudents?.message}
				inputProps={{
					...register('estimatedNumberOfStudents', { required: true }),
					placeholder: 'A number or range'
				}}
			/>
		</>
	);
};

export default BasicCourseDetailsForm;
