export const Link = {
	// The styles all button have in common
	baseStyle: {
		fontWeight: '600',
		color: 'border.800',
		borderBottom: '4px',
		borderBottomStyle: 'solid',
		borderBottomColor: 'border.800',
		px: '1',
		_hover: {
			color: 'border.400',
			borderBottomColor: 'border.400',
			textDecoration: 'none'
		}
	},
	variants: {
		blue: {
			color: 'brandBlue.500',
			borderBottom: '4px',
			borderBottomStyle: 'solid',
			borderBottomColor: 'brandBlue.500',
			_hover: {
				color: 'brandBlue.800',
				borderBottomColor: 'brandBlue.800',
				textDecoration: 'none'
			}
		},
		noBorder: {
			borderBottom: '0px'
		},
		external: {
			borderBottom: '0px',
			px: 0,
			fontSize: 'md',
			color: 'brandBlue.500',
			_hover: {
				color: 'brandBlue.400'
			}
		}
	},
	sizes: {
		lg: {
			fontSize: 'lg',
			py: 1,
			px: 1
		},
		md: {
			borderBottom: '2px'
		}
	},
	defaultProps: {
		size: 'lg'
	}
};
