import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Heading, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

import { SetupAccordion } from 'components';
import { CreateCourseFormGlobalState } from 'types';
import { CreateCourseFromSchema } from 'types/schema';
import { useAppSelector, useAppDispatch } from 'store';
import { updateCourseId } from 'store/slices/contentSlice';
import { selectGlobalState, resetGlobalState } from 'store/slices/createCourseFormSlice';
import { useTrackEvent } from 'hooks';
import { InstructorApprovedModal } from 'components/modals';

const Review: React.FC = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const trackEvent = useTrackEvent();

	const { courseId: _courseId } = useParams<{ courseId?: string }>();
	const $globalState = useAppSelector(selectGlobalState);
	const hasCapturedFirstEdit = useRef(false);

	/**
	 * Setup react-hook-form so it can be handed over to the `<FormProvider>`
	 */
	const formProps = useForm<CreateCourseFormGlobalState>({
		defaultValues: {
			...$globalState
		},
		mode: 'onTouched',
		resolver: yupResolver(CreateCourseFromSchema)
	});

	/**
	 * The user has be directed to `/review/:courseId` without a JWT present, meaning it is
	 * most likely the user navigated here using the navigation drawer's course list.  We need
	 * to prepare the form & store for editing the desired course. This means resetting the
	 * form state, clearing out leftovers in global state and updating the courseId.
	 */
	useEffect(() => {
		if (_courseId) {
			formProps.reset({});
			dispatch(resetGlobalState({}));
			dispatch(updateCourseId(Number(_courseId)));
			history.push('/review');
		}

		/**
		 * Track when the user starts the Review form
		 */
		trackEvent('review-form-started');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * The form is now dirty meaning the user has made their first edit (for this session) and if
	 * we haven't captured the first edit we will track this event and mark `hasCapturedFirstEdit`
	 * true.
	 *
	 * Perhaps we need to incorporate `localStorage` at some point to make this more precise.
	 */
	useEffect(() => {
		if (formProps.formState.isDirty && hasCapturedFirstEdit.current === false) {
			trackEvent('review-form-updated', {
				valid: false
			});
			hasCapturedFirstEdit.current = true;
		}
	}, [formProps.formState.isDirty, trackEvent]);

	return (
		<>
			<Heading size="2xl" alignSelf="flex-start">
				Get your course student-ready
			</Heading>

			<Text fontSize="lg" py="6">
				Fill out the rest of your information below so our team can get your course ready for
				student access.
			</Text>

			<FormProvider {...formProps}>
				<SetupAccordion />
			</FormProvider>

			<InstructorApprovedModal />
		</>
	);
};

export default Review;
